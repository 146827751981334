import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import {
  SurveyDetailsFragmentDoc,
  ISurveyAnswerOptionDetailsFragmentDoc,
  SurveySubcategoryTemplateDetailsFragmentDoc,
  SurveyGetBuilderFragmentDoc,
} from '../../../../../features/core/graphql/survey/__generated__/fragments'
import { CompanySelectedRegulationFragmentDoc } from '../../../../../features/core/graphql/company/__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SurveyBuilderGetPageQueryVariables = Types.Exact<{
  surveyFilterArg?: Types.InputMaybe<Types.SurveyFilterArg>
}>

export type SurveyBuilderGetPageQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    get: {
      __typename: 'SurveyGetPayload'
      surveys: Array<{
        __typename: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: Types.SurveyType
        createdAt: any
        lastUsedAt?: any | null
        company: {
          __typename: 'Company'
          id: string
          selectedRegulations: Array<{
            __typename: 'Regulation'
            id: string
            regulationId: string
            name: string
            uiCode?: any | null
            logoPath?: string | null
            description?: string | null
          }>
          currentSubscriptionTier?: {
            __typename: 'SubscriptionTier'
            id: string
            name: string
            features?: Array<
              | {
                  __typename: 'SubscriptionFeatureBoolean'
                  available: boolean
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
              | {
                  __typename: 'SubscriptionFeatureIntRange'
                  minValue?: number | null
                  maxValue?: number | null
                  id: string
                  name: string
                  code: string
                  description?: string | null
                }
            > | null
          } | null
        }
        sessions: Array<{
          __typename: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename: 'SurveyCategory'
              id: string
              regulation?: {
                __typename: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }>
    }
  }
}

export type SurveyBuilderCategoriesGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SurveyCategoryFilterArg>
}>

export type SurveyBuilderCategoriesGetQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    categoriesGet: {
      __typename: 'SurveyCategoriesGetPayload'
      categories: Array<{
        __typename: 'SurveyCategory'
        id: string
        categoryId: string
        uiCode?: any | null
        name?: string | null
        isActive: boolean
        regulation?: {
          __typename: 'Regulation'
          id: string
          name: string
          logoPath?: string | null
          uiCode?: any | null
          description?: string | null
          isActive: boolean
        } | null
        subcategoryTemplates: Array<{
          __typename: 'SurveySubcategoryTemplate'
          id: string
        }>
      }>
    }
  }
}

export type SurveyBuilderSubcategoryTemplatesGetQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SurveySubcategoryTemplatesFilterArg>
}>

export type SurveyBuilderSubcategoryTemplatesGetQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    subcategoryTemplatesGet: {
      __typename: 'SurveySubcategoryTemplatesGetPayload'
      subcategoryTemplates: Array<{
        __typename: 'SurveySubcategoryTemplate'
        id: string
        uiCode?: any | null
        isSelected?: boolean | null
        isConfirmed?: boolean | null
        isActive: boolean
        isGuided: boolean
        header: string
        description?: string | null
        sortOrder: any
        questionTemplatesCount: any
        questionTemplates: Array<{
          __typename: 'SurveyQuestionTemplate'
          id: string
          uiCode?: any | null
          modifiers?: any | null
          label: string
          description?: string | null
          sortOrder: any
          answerOptionTemplates?: Array<{
            __typename: 'SurveyAnswerOptionTemplate'
            id: string
            value?: string | null
          }> | null
          type: {
            __typename: 'QuestionType'
            id: string
            name: string
            code: string
            availableModifiers?: any | null
            isActive: boolean
            uiCode: any
          }
        }>
        category: {
          __typename: 'SurveyCategory'
          id: string
          regulation?: {
            __typename: 'Regulation'
            id: string
            name: string
            uiCode?: any | null
          } | null
        }
      }>
    }
  }
}

export type SurveyGetBuilderQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.SurveyFilterArg>
  pagination?: Types.InputMaybe<Types.PaginationArg>
  order?: Types.InputMaybe<
    Array<Types.SurveyGetOrderArg> | Types.SurveyGetOrderArg
  >
}>

export type SurveyGetBuilderQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    get: {
      __typename: 'SurveyGetPayload'
      surveys: Array<{
        __typename: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: Types.SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
      }>
      paginationInfo: {
        __typename: 'PaginationInfo'
        totalItems: any
        totalPages: any
      }
    }
  }
}

export type SurveyCopyToCompanyMutationVariables = Types.Exact<{
  input: Types.SurveyCopyInput
}>

export type SurveyCopyToCompanyMutation = {
  __typename: 'Mutation'
  survey: {
    __typename: 'NamespaceSurveyMutation'
    copy: {
      __typename: 'SurveyCopyPayload'
      survey: {
        __typename: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: Types.SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
      }
    }
  }
}

export type SurveyBuilderGuidedQuestionsGetQueryVariables = Types.Exact<{
  input: Types.SurveyQuestionsGetInput
}>

export type SurveyBuilderGuidedQuestionsGetQuery = {
  __typename: 'Query'
  survey: {
    __typename: 'NamespaceSurveyQuery'
    questionsGet: {
      __typename: 'SurveyQuestionsGetPayload'
      questions: Array<{
        __typename: 'SurveyQuestion'
        id: string
        uiCode?: any | null
        modifiers?: any | null
        label: string
        description?: string | null
        subcategoryId: string
        sortOrder?: any | null
        datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
        datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
        guidedStepId?: string | null
        questionTemplateId?: string | null
        isTemplateQuestion?: boolean | null
        answerOptions: Array<
          | {
              __typename: 'DatamapAnswerOption'
              id: string
              isSensitive?: boolean | null
              isCustom: boolean
              value?: string | null
              datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
              sortOrder?: any | null
              entity?:
                | {
                    __typename: 'Asset'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataElement'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataLifecycle'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataOwnership'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSharing'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSource'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSubject'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Department'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'LegalBasis'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Process'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'ProcessingType'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Region'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'SurveyAnswerOption'
                    id: string
                    name?: string | null
                    uiCode?: any | null
                  }
                | null
            }
          | {
              __typename: 'DatamapCustomAnswerOption'
              uiCode?: any | null
              value?: string | null
              sortOrder?: any | null
              isSensitive?: boolean | null
              isCustom: boolean
              datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
            }
          | {
              __typename: 'SurveyAnswerOption'
              id: string
              value?: string | null
              uiCode?: any | null
              sortOrder?: any | null
            }
          | { __typename: 'SurveyVirtualAnswerOption' }
        >
        type: {
          __typename: 'QuestionType'
          id: string
          name: string
          code: string
          availableModifiers?: any | null
          uiCode: any
        }
      }>
    }
  }
}

export const SurveyBuilderGetPageDocument = gql`
  query SurveyBuilderGetPage($surveyFilterArg: SurveyFilterArg) {
    survey {
      get(input: { filter: $surveyFilterArg }) {
        surveys {
          ...SurveyDetails
          company {
            id
            selectedRegulations {
              ...CompanySelectedRegulation
            }
            currentSubscriptionTier {
              id
              name
              features {
                id
                name
                code
                description
                ... on SubscriptionFeatureIntRange {
                  minValue
                  maxValue
                }
                ... on SubscriptionFeatureBoolean {
                  available
                }
              }
            }
          }
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
  ${CompanySelectedRegulationFragmentDoc}
`

/**
 * __useSurveyBuilderGetPageQuery__
 *
 * To run a query within a React component, call `useSurveyBuilderGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyBuilderGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyBuilderGetPageQuery({
 *   variables: {
 *      surveyFilterArg: // value for 'surveyFilterArg'
 *   },
 * });
 */
export function useSurveyBuilderGetPageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >(SurveyBuilderGetPageDocument, options)
}
export function useSurveyBuilderGetPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >(SurveyBuilderGetPageDocument, options)
}
export function useSurveyBuilderGetPageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyBuilderGetPageQuery,
    SurveyBuilderGetPageQueryVariables
  >(SurveyBuilderGetPageDocument, options)
}
export type SurveyBuilderGetPageQueryHookResult = ReturnType<
  typeof useSurveyBuilderGetPageQuery
>
export type SurveyBuilderGetPageLazyQueryHookResult = ReturnType<
  typeof useSurveyBuilderGetPageLazyQuery
>
export type SurveyBuilderGetPageSuspenseQueryHookResult = ReturnType<
  typeof useSurveyBuilderGetPageSuspenseQuery
>
export type SurveyBuilderGetPageQueryResult = Apollo.QueryResult<
  SurveyBuilderGetPageQuery,
  SurveyBuilderGetPageQueryVariables
>
export const SurveyBuilderCategoriesGetDocument = gql`
  query SurveyBuilderCategoriesGet($filter: SurveyCategoryFilterArg) {
    survey {
      categoriesGet(input: { filter: $filter }) {
        categories {
          id
          categoryId
          uiCode
          name
          isActive
          regulation {
            id
            name
            logoPath
            uiCode
            description
            isActive
          }
          subcategoryTemplates {
            id
          }
        }
      }
    }
  }
`

/**
 * __useSurveyBuilderCategoriesGetQuery__
 *
 * To run a query within a React component, call `useSurveyBuilderCategoriesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyBuilderCategoriesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyBuilderCategoriesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyBuilderCategoriesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >(SurveyBuilderCategoriesGetDocument, options)
}
export function useSurveyBuilderCategoriesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >(SurveyBuilderCategoriesGetDocument, options)
}
export function useSurveyBuilderCategoriesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyBuilderCategoriesGetQuery,
    SurveyBuilderCategoriesGetQueryVariables
  >(SurveyBuilderCategoriesGetDocument, options)
}
export type SurveyBuilderCategoriesGetQueryHookResult = ReturnType<
  typeof useSurveyBuilderCategoriesGetQuery
>
export type SurveyBuilderCategoriesGetLazyQueryHookResult = ReturnType<
  typeof useSurveyBuilderCategoriesGetLazyQuery
>
export type SurveyBuilderCategoriesGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyBuilderCategoriesGetSuspenseQuery
>
export type SurveyBuilderCategoriesGetQueryResult = Apollo.QueryResult<
  SurveyBuilderCategoriesGetQuery,
  SurveyBuilderCategoriesGetQueryVariables
>
export const SurveyBuilderSubcategoryTemplatesGetDocument = gql`
  query SurveyBuilderSubcategoryTemplatesGet(
    $filter: SurveySubcategoryTemplatesFilterArg
  ) {
    survey {
      subcategoryTemplatesGet(input: { filter: $filter }) {
        subcategoryTemplates {
          ...SurveySubcategoryTemplateDetails
        }
      }
    }
  }
  ${SurveySubcategoryTemplateDetailsFragmentDoc}
`

/**
 * __useSurveyBuilderSubcategoryTemplatesGetQuery__
 *
 * To run a query within a React component, call `useSurveyBuilderSubcategoryTemplatesGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyBuilderSubcategoryTemplatesGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyBuilderSubcategoryTemplatesGetQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSurveyBuilderSubcategoryTemplatesGetQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >(SurveyBuilderSubcategoryTemplatesGetDocument, options)
}
export function useSurveyBuilderSubcategoryTemplatesGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >(SurveyBuilderSubcategoryTemplatesGetDocument, options)
}
export function useSurveyBuilderSubcategoryTemplatesGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >(SurveyBuilderSubcategoryTemplatesGetDocument, options)
}
export type SurveyBuilderSubcategoryTemplatesGetQueryHookResult = ReturnType<
  typeof useSurveyBuilderSubcategoryTemplatesGetQuery
>
export type SurveyBuilderSubcategoryTemplatesGetLazyQueryHookResult =
  ReturnType<typeof useSurveyBuilderSubcategoryTemplatesGetLazyQuery>
export type SurveyBuilderSubcategoryTemplatesGetSuspenseQueryHookResult =
  ReturnType<typeof useSurveyBuilderSubcategoryTemplatesGetSuspenseQuery>
export type SurveyBuilderSubcategoryTemplatesGetQueryResult =
  Apollo.QueryResult<
    SurveyBuilderSubcategoryTemplatesGetQuery,
    SurveyBuilderSubcategoryTemplatesGetQueryVariables
  >
export const SurveyGetBuilderDocument = gql`
  query SurveyGetBuilder(
    $filter: SurveyFilterArg
    $pagination: PaginationArg
    $order: [SurveyGetOrderArg!]
  ) {
    survey {
      get(input: { filter: $filter, pagination: $pagination, order: $order }) {
        surveys {
          ...SurveyGetBuilderFragment
        }
        paginationInfo {
          totalItems
          totalPages
        }
      }
    }
  }
  ${SurveyGetBuilderFragmentDoc}
`

/**
 * __useSurveyGetBuilderQuery__
 *
 * To run a query within a React component, call `useSurveyGetBuilderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyGetBuilderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyGetBuilderQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSurveyGetBuilderQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SurveyGetBuilderQuery,
    SurveyGetBuilderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SurveyGetBuilderQuery, SurveyGetBuilderQueryVariables>(
    SurveyGetBuilderDocument,
    options,
  )
}
export function useSurveyGetBuilderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyGetBuilderQuery,
    SurveyGetBuilderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyGetBuilderQuery,
    SurveyGetBuilderQueryVariables
  >(SurveyGetBuilderDocument, options)
}
export function useSurveyGetBuilderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyGetBuilderQuery,
    SurveyGetBuilderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyGetBuilderQuery,
    SurveyGetBuilderQueryVariables
  >(SurveyGetBuilderDocument, options)
}
export type SurveyGetBuilderQueryHookResult = ReturnType<
  typeof useSurveyGetBuilderQuery
>
export type SurveyGetBuilderLazyQueryHookResult = ReturnType<
  typeof useSurveyGetBuilderLazyQuery
>
export type SurveyGetBuilderSuspenseQueryHookResult = ReturnType<
  typeof useSurveyGetBuilderSuspenseQuery
>
export type SurveyGetBuilderQueryResult = Apollo.QueryResult<
  SurveyGetBuilderQuery,
  SurveyGetBuilderQueryVariables
>
export const SurveyCopyToCompanyDocument = gql`
  mutation SurveyCopyToCompany($input: SurveyCopyInput!) {
    survey {
      copy(input: $input) {
        survey {
          ...SurveyGetBuilderFragment
        }
      }
    }
  }
  ${SurveyGetBuilderFragmentDoc}
`
export type SurveyCopyToCompanyMutationFn = Apollo.MutationFunction<
  SurveyCopyToCompanyMutation,
  SurveyCopyToCompanyMutationVariables
>

/**
 * __useSurveyCopyToCompanyMutation__
 *
 * To run a mutation, you first call `useSurveyCopyToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyCopyToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyCopyToCompanyMutation, { data, loading, error }] = useSurveyCopyToCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyCopyToCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyCopyToCompanyMutation,
    SurveyCopyToCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyCopyToCompanyMutation,
    SurveyCopyToCompanyMutationVariables
  >(SurveyCopyToCompanyDocument, options)
}
export type SurveyCopyToCompanyMutationHookResult = ReturnType<
  typeof useSurveyCopyToCompanyMutation
>
export type SurveyCopyToCompanyMutationResult =
  Apollo.MutationResult<SurveyCopyToCompanyMutation>
export type SurveyCopyToCompanyMutationOptions = Apollo.BaseMutationOptions<
  SurveyCopyToCompanyMutation,
  SurveyCopyToCompanyMutationVariables
>
export const SurveyBuilderGuidedQuestionsGetDocument = gql`
  query SurveyBuilderGuidedQuestionsGet($input: SurveyQuestionsGetInput!) {
    survey {
      questionsGet(input: $input) {
        questions {
          id
          uiCode
          modifiers
          label
          description
          subcategoryId
          sortOrder
          datamapEntityTypeCode
          datamapParentEntityTypeCode
          guidedStepId
          answerOptions {
            ...ISurveyAnswerOptionDetails
          }
          questionTemplateId
          isTemplateQuestion @client
          type {
            id
            name
            code
            availableModifiers
            uiCode
          }
        }
      }
    }
  }
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`

/**
 * __useSurveyBuilderGuidedQuestionsGetQuery__
 *
 * To run a query within a React component, call `useSurveyBuilderGuidedQuestionsGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyBuilderGuidedQuestionsGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyBuilderGuidedQuestionsGetQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSurveyBuilderGuidedQuestionsGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  > &
    (
      | {
          variables: SurveyBuilderGuidedQuestionsGetQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  >(SurveyBuilderGuidedQuestionsGetDocument, options)
}
export function useSurveyBuilderGuidedQuestionsGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  >(SurveyBuilderGuidedQuestionsGetDocument, options)
}
export function useSurveyBuilderGuidedQuestionsGetSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SurveyBuilderGuidedQuestionsGetQuery,
    SurveyBuilderGuidedQuestionsGetQueryVariables
  >(SurveyBuilderGuidedQuestionsGetDocument, options)
}
export type SurveyBuilderGuidedQuestionsGetQueryHookResult = ReturnType<
  typeof useSurveyBuilderGuidedQuestionsGetQuery
>
export type SurveyBuilderGuidedQuestionsGetLazyQueryHookResult = ReturnType<
  typeof useSurveyBuilderGuidedQuestionsGetLazyQuery
>
export type SurveyBuilderGuidedQuestionsGetSuspenseQueryHookResult = ReturnType<
  typeof useSurveyBuilderGuidedQuestionsGetSuspenseQuery
>
export type SurveyBuilderGuidedQuestionsGetQueryResult = Apollo.QueryResult<
  SurveyBuilderGuidedQuestionsGetQuery,
  SurveyBuilderGuidedQuestionsGetQueryVariables
>
export const namedOperations = {
  Query: {
    SurveyBuilderGetPage: 'SurveyBuilderGetPage',
    SurveyBuilderCategoriesGet: 'SurveyBuilderCategoriesGet',
    SurveyBuilderSubcategoryTemplatesGet:
      'SurveyBuilderSubcategoryTemplatesGet',
    SurveyGetBuilder: 'SurveyGetBuilder',
    SurveyBuilderGuidedQuestionsGet: 'SurveyBuilderGuidedQuestionsGet',
  },
  Mutation: {
    SurveyCopyToCompany: 'SurveyCopyToCompany',
  },
}
