import * as Types from '../../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import {
  SurveyDetailsFragmentDoc,
  ISurveyAnswerOptionDetailsFragmentDoc,
  SurveySubcategoryTemplateDetailsFragmentDoc,
  SurveyGetBuilderFragmentDoc,
} from '../../__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type SurveySubcategoryCreateMutationVariables = Types.Exact<{
  surveyId: Types.Scalars['ID']['input']
  header: Types.Scalars['String']['input']
  description?: Types.InputMaybe<Types.Scalars['String']['input']>
  sortOrder?: Types.InputMaybe<Types.Scalars['NonNegativeInt']['input']>
}>

export type SurveySubcategoryCreateMutation = {
  __typename: 'Mutation'
  survey: {
    __typename: 'NamespaceSurveyMutation'
    subcategoryCreate: {
      __typename: 'SurveySubcategoryCreatePayload'
      survey: {
        __typename: 'Survey'
        id: string
        surveyId: string
        name: string
        questionsCount: any
        isDraft: boolean
        isTemplate: boolean
        type: Types.SurveyType
        createdAt: any
        lastUsedAt?: any | null
        sessions: Array<{
          __typename: 'SurveySession'
          id: string
          finishedAt: any
          deadlineAt?: any | null
        }>
        subcategories: Array<{
          __typename: 'SurveySubcategory'
          id: string
          header: string
          uiCode?: any | null
          description?: string | null
          isGuided: boolean
          sortOrder?: any | null
          questionsCount: any
          questions: Array<{
            __typename: 'SurveyQuestion'
            id: string
            uiCode?: any | null
            modifiers?: any | null
            label: string
            description?: string | null
            subcategoryId: string
            sortOrder?: any | null
            datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
            guidedStepId?: string | null
            questionTemplateId?: string | null
            isTemplateQuestion?: boolean | null
            answerOptions: Array<
              | {
                  __typename: 'DatamapAnswerOption'
                  id: string
                  isSensitive?: boolean | null
                  isCustom: boolean
                  value?: string | null
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                  sortOrder?: any | null
                  entity?:
                    | {
                        __typename: 'Asset'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataElement'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataLifecycle'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataOwnership'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSharing'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSource'
                        id: string
                        needsValue: boolean
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'DataSubject'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Department'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'LegalBasis'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Process'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'ProcessingType'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'Region'
                        id: string
                        name: string
                        uiCode?: any | null
                      }
                    | {
                        __typename: 'SurveyAnswerOption'
                        id: string
                        name?: string | null
                        uiCode?: any | null
                      }
                    | null
                }
              | {
                  __typename: 'DatamapCustomAnswerOption'
                  uiCode?: any | null
                  value?: string | null
                  sortOrder?: any | null
                  isSensitive?: boolean | null
                  isCustom: boolean
                  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                }
              | {
                  __typename: 'SurveyAnswerOption'
                  id: string
                  value?: string | null
                  uiCode?: any | null
                  sortOrder?: any | null
                }
              | { __typename: 'SurveyVirtualAnswerOption' }
            >
            type: {
              __typename: 'QuestionType'
              id: string
              name: string
              code: string
              availableModifiers?: any | null
              isActive: boolean
              uiCode: any
            }
          }>
          guidedSteps?: Array<{
            __typename: 'SurveyGuidedStep'
            id: string
            name: string
            description?: string | null
            sortOrder?: any | null
          }> | null
          subcategoryTemplate?: {
            __typename: 'SurveySubcategoryTemplate'
            id: string
            header: string
            isSelected?: boolean | null
            isConfirmed?: boolean | null
            description?: string | null
            sortOrder: any
            questionTemplatesCount: any
            category: {
              __typename: 'SurveyCategory'
              id: string
              regulation?: {
                __typename: 'Regulation'
                id: string
                name: string
                uiCode?: any | null
              } | null
            }
          } | null
        }>
      }
    }
  }
}

export const SurveySubcategoryCreateDocument = gql`
  mutation SurveySubcategoryCreate(
    $surveyId: ID!
    $header: String!
    $description: String
    $sortOrder: NonNegativeInt
  ) {
    survey {
      subcategoryCreate(
        input: {
          surveyId: $surveyId
          header: $header
          description: $description
          sortOrder: $sortOrder
        }
      ) {
        survey {
          ...SurveyDetails
        }
      }
    }
  }
  ${SurveyDetailsFragmentDoc}
`
export type SurveySubcategoryCreateMutationFn = Apollo.MutationFunction<
  SurveySubcategoryCreateMutation,
  SurveySubcategoryCreateMutationVariables
>

/**
 * __useSurveySubcategoryCreateMutation__
 *
 * To run a mutation, you first call `useSurveySubcategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveySubcategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveySubcategoryCreateMutation, { data, loading, error }] = useSurveySubcategoryCreateMutation({
 *   variables: {
 *      surveyId: // value for 'surveyId'
 *      header: // value for 'header'
 *      description: // value for 'description'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSurveySubcategoryCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveySubcategoryCreateMutation,
    SurveySubcategoryCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveySubcategoryCreateMutation,
    SurveySubcategoryCreateMutationVariables
  >(SurveySubcategoryCreateDocument, options)
}
export type SurveySubcategoryCreateMutationHookResult = ReturnType<
  typeof useSurveySubcategoryCreateMutation
>
export type SurveySubcategoryCreateMutationResult =
  Apollo.MutationResult<SurveySubcategoryCreateMutation>
export type SurveySubcategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  SurveySubcategoryCreateMutation,
  SurveySubcategoryCreateMutationVariables
>
export const namedOperations = {
  Mutation: {
    SurveySubcategoryCreate: 'SurveySubcategoryCreate',
  },
}
