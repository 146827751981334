/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AuthMfaConfigureConfirmDocument,
  AuthMfaConfigureConfirmMutationVariables,
  AuthSignUpConfirmDocument,
  AuthSignUpConfirmMutationVariables,
  AuthPasswordChangeMutationVariables,
  AuthPasswordChangeDocument,
  AuthTokenRefreshMutationVariables,
  AuthTokenRefreshDocument,
  UserDeleteMutationVariables,
  UserDeleteDocument,
  AuthLogoutDocument,
  AuthPasswordRecoverInitMutationVariables,
  AuthPasswordRecoverInitDocument,
  AuthPasswordRecoverConfirmMutationVariables,
  AuthPasswordRecoverConfirmDocument,
} from '@generated/types.d'
import { PqApolloClient } from './index'

export const validateNewUserToken = async () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 2000)
  })
}

export const authSignUpConfirm = async (
  client: PqApolloClient,
  variables: AuthSignUpConfirmMutationVariables,
  graphqlDocument: typeof AuthSignUpConfirmDocument = AuthSignUpConfirmDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
  })
}

export const authPasswordChange = async (
  client: PqApolloClient,
  variables: AuthPasswordChangeMutationVariables,
  document: typeof AuthPasswordChangeDocument = AuthPasswordChangeDocument,
) => {
  await client.resetStore()
  return client.mutate({
    variables,
    mutation: document,
    fetchPolicy: 'no-cache',
    refetchQueries: ['AuthCurrentUser'],
  })
}

export const authTokenRefresh = async (
  client: PqApolloClient,
  variables: AuthTokenRefreshMutationVariables,
  document: typeof AuthTokenRefreshDocument = AuthTokenRefreshDocument,
) => {
  client.resetStore()
  return client.mutate({
    variables,
    mutation: document,
    fetchPolicy: 'no-cache',
  })
}

export const authLogout = async (client: PqApolloClient) => {
  return client.mutate({
    mutation: AuthLogoutDocument,
  })
}

export const userDelete = async (
  client: PqApolloClient,
  mutationVariables: UserDeleteMutationVariables,
) => {
  return client.mutate({
    variables: mutationVariables,
    mutation: UserDeleteDocument,
    refetchQueries: [
      'UserGet',
      'UserGetCompanySurveysAudiences',
      'CompanyFetchAudienceGroups',
    ],
    update: (cache) => {
      const userIds = mutationVariables?.input?.filter?.userIds
      cache.evict({
        id: `User:${userIds?.[0]}`,
      })
      cache.gc()
    },
  })
}

export const authMfaConfigureConfirm = async (
  client: PqApolloClient,
  variables: AuthMfaConfigureConfirmMutationVariables,
  graphqlDocument: typeof AuthMfaConfigureConfirmDocument = AuthMfaConfigureConfirmDocument,
) => {
  const authMfaConfConfirmRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
  })

  return authMfaConfConfirmRes
}

export const passswordRestoreInit = async (
  client: PqApolloClient,
  variables: AuthPasswordRecoverInitMutationVariables,
  graphqlDocument: typeof AuthPasswordRecoverInitDocument = AuthPasswordRecoverInitDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const passswordRestoreConfirm = async (
  client: PqApolloClient,
  variables: AuthPasswordRecoverConfirmMutationVariables,
  graphqlDocument: typeof AuthPasswordRecoverConfirmDocument = AuthPasswordRecoverConfirmDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
