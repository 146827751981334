import * as styleVars from '@common/styles/constants'

import { Global, css } from '@emotion/react'

import { mediaQuery } from '@common/utils/breakpoints'

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        * {
          padding: 0;
          margin: 0;
          box-sizing: border-box;
        }

        ul {
          list-style: none;
        }

        .ReactModal__Body--open,
        .ReactModal__Html--open {
          overflow: hidden;
        }

        html,
        body {
          overflow: auto;
          width: 100%;
          height: 100%; // need this for making sure image snapshots in Cypress work properly
        }

        section {
          width: 100%;
        }

        a,
        button {
          color: inherit;
          outline: 0;
          width: auto;
        }

        a {
          text-decoration: none;
        }

        .pq-text-decoration-underline {
          text-decoration: underline !important;
        }
        .pq-font-size-12 {
          font-size: 12px !important;
        }
        .pq-font-weight-bold {
          font-weight: bold !important;
        }
        .pq-font-weight-normal {
          font-weight: normal !important;
        }
        .pq-font-family-poppins {
          font-family: Poppins !important;
        }

        button {
          background: none;
          cursor: pointer;
        }

        .text-center {
          text-align: center;
        }

        input,
        input:disabled {
          -webkit-appearance: none;
          background: transparent;
        }

        button,
        input {
          border: none;
        }

        svg,
        img {
          display: block;
        }

        @-webkit-keyframes autofill {
          0%,
          100% {
            color: #0d1331;
            background: transparent;
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          background: transparent;
          -webkit-animation-delay: 1s; /* Safari support - any positive time runs instantly */
          -webkit-animation-name: autofill;
          -webkit-animation-fill-mode: both;
          transition:
            background-color 5000s ease-in-out 0s,
            color 5000s ease-in-out 0s;
          transition-delay:
            background-color 5000s,
            color 5000s;
        }

        @font-face {
          font-family: 'Poppins';
          font-weight: normal;
          src: url('/static/fonts/Poppins/Poppins-Regular.ttf')
            format('truetype');
        }

        @font-face {
          font-family: 'Poppins';
          font-weight: bold;
          src: url('/static/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
        }

        @font-face {
          font-family: 'Poppins';
          font-weight: 600;
          src: url('/static/fonts/Poppins/Poppins-SemiBold.ttf')
            format('truetype');
        }

        @font-face {
          font-family: 'Poppins';
          font-weight: 500;
          src: url('/static/fonts/Poppins/Poppins-Medium.ttf')
            format('truetype');
        }

        body {
          font-size: var(--fs-base);
          font-family: 'Poppins';
        }

        input {
          font-family: 'Poppins';
        }

        h1 {
          font-size: var(--fs-h1);
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #0d1331;
        }

        h2 {
          font-size: var(--fs-h2);
        }

        h3 {
          font-size: var(--fs-h3); /* 16px */
        }

        h4 {
          font-size: var(--fs-h4); /* 14px */
        }

        h5 {
          font-size: var(--fs-h5); /* 12px */
        }

        p {
          color: #3a3a3a;
          font-size: 0.875rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        /**
         * Reusable Theme Classes
         */

        .pq-absolute-center {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .pq-section-wrapper {
          padding: 20px;
          border-radius: 16px;
          background-color: ${styleVars.white};
          box-shadow: 0 2px 24px 0 rgba(223, 231, 255, 0.2);
        }

        .pq-section-sub-header {
          font-family: Poppins;
          font-size: 1.125rem;

          ${mediaQuery('xLarge')} {
            font-size: 1rem;
          }

          font-weight: bold;
          line-height: 1.67;
          color: ${styleVars.darkBlack};
        }

        /**
         * Utility
         */
        .pq-font-styles {
          font-family: Poppins;
          font-size: 10px;
        }
        .pq-svg-fill-primary-blue {
          & path:last-child {
            fill: ${styleVars.primaryBlue};
          }
        }
        .pq-svg-fill-primary-red {
          & path:last-child {
            fill: ${styleVars.primaryRed};
          }
        }
        .pq-text-color-primary-red {
          color: ${styleVars.primaryRed};
        }
        .pq-text-color-primary-blue {
          color: ${styleVars.primaryBlue};
        }
        .pq-text-color-dark-black {
          color: ${styleVars.darkBlack};
        }
        .pq-text-color-light-grey {
          color: ${styleVars.lightGrey};
        }
        .pq-font-size-14 {
          font-size: 14px;
        }
        .pq-font-weight-bold {
          font-weight: 600;
        }
        .pq-box-shadow {
          box-shadow: 0 2px 16px 0 rgba(25, 25, 25, 0.16);
        }
        .pq-color-faded-white {
          color: ${styleVars.fadedWhite} !important;
        }
        .pq-color-dark-black {
          color: ${styleVars.darkBlack} !important;
        }
        .pq-color-black2 {
          color: ${styleVars.black2};
        }
        .pq-background-dark-blue {
          background-color: ${styleVars.outlineBtnBorder} !important;
          &::after {
            background-color: ${styleVars.outlineBtnBorder} !important;
          }
        }
        .pq-background-white {
          background-color: ${styleVars.white} !important;
          &::after {
            background-color: ${styleVars.white} !important;
          }
        }
        .pq-justify-content-center {
          justify-content: center;
        }
        .pq-justify-content-between {
          justify-content: space-between;
        }
        .pq-align-items-center {
          align-items: center;
        }
        .pq-align-items-start {
          align-items: flex-start;
        }
        .pq-btn-primary {
          font-family: Poppins;
          font-size: 14px;
          padding: 14px 58px;
          font-weight: 600;
          text-align: center;
          color: ${styleVars.white};
          border-radius: 8px;
          background-color: ${styleVars.primaryBlue};
        }
        .pq-vis-hidden {
          visibility: hidden;
        }
        .pq-p-absolute {
          position: absolute;
        }
        .pq-d-block {
          display: block;
        }
        .pq-d-inline {
          display: inline;
        }
        .pq-d-none {
          display: none;
        }
        .pq-flex-direction-row {
          flex-direction: row;
        }
        .pq-flex-direction-column {
          flex-direction: column;
        }
        .pq-d-inline-flex {
          display: inline-flex;
        }
        .pq-d-inline-block {
          display: inline-block !important;
        }
        .pq-cursor-auto:hover {
          cursor: auto !important;
          & * {
            cursor: auto !important;
          }
        }
        .pq-hover-pointer:hover {
          cursor: pointer !important;
          & * {
            cursor: pointer !important;
          }
        }
        .pq-whitespace-nowrap {
          white-space: nowrap;
        }
        .pq-whitespace-prewrap {
          white-space: pre-wrap !important;
        }
        .pq-float-left {
          float: left;
        }
        .pq-float-right {
          float: right;
        }
        .pq-text-center {
          text-align: center !important;
        }
        .pq-text-right {
          text-align: right !important;
        }
        .pq-text-left {
          text-align: left !important;
        }
        .pq-d-flex {
          display: flex;
        }

        .pq-gap-0 {
          gap: 0;
        }
        .pq-gap-1 {
          gap: 0.625em; // 10px
        }

        .pq-gap-2 {
          gap: 1.25em; // 20px
        }

        .pq-gap-3 {
          gap: 1.875em; // 30px
        }

        .pq-gap-4 {
          gap: 2.5em; // 40px
        }

        .pq-gap-5 {
          gap: 3.125em; // 50px
        }

        .pq-flex-align-center {
          justify-content: center;
        }

        .pq-flex-align-baseline {
          align-items: baseline;
        }

        .pq-flex-1 {
          flex: 1;
        }

        .pq-flex-2 {
          flex: 2;
        }

        .pq-flex-3 {
          flex: 3;
        }

        .pq-flex-4 {
          flex: 4;
        }

        .pq-w-100 {
          width: 100%;
        }
        .pq-h-100 {
          height: 100%;
        }
        .pq-h-100vh {
          height: 100vh;
        }

        .pq-w-20 {
          width: 20%;
        }

        .pq-w-75 {
          width: 75%;
        }
        .pq-w-80 {
          width: 80% !important;
        }

        .pq-w-50 {
          width: 50%;
        }

        .pq-mt-0 {
          margin-top: 0;
        }
        .pq-mt-1 {
          margin-top: 0.625em; // 10px
        }

        .pq-mt-2 {
          margin-top: 1.25em; // 20px
        }

        .pq-mt-3 {
          margin-top: 1.875em; // 30px
        }

        .pq-mt-4 {
          margin-top: 2.5em; // 40px
        }

        .pq-mt-5 {
          margin-top: 3.125em; // 50px
        }
        .pq-mr-0 {
          margin-right: 0;
        }
        .pq-mr-1 {
          margin-right: 0.625em;
        }

        .pq-mr-2 {
          margin-right: 1.25em;
        }

        .pq-mr-3 {
          margin-right: 1.875em;
        }

        .pq-mr-4 {
          margin-right: 2.5em;
        }

        .pq-mr-5 {
          margin-right: 3.125em;
        }

        .pq-mt-5 {
          margin-top: 3.125em;
        }
        .pq-mb-0 {
          margin-bottom: 0;
        }
        .pq-mb-1 {
          margin-bottom: 0.625em;
        }

        .pq-mb-2 {
          margin-bottom: 1.25em;
        }

        .pq-mb-3 {
          margin-bottom: 1.875em;
        }

        .pq-mb-4 {
          margin-bottom: 2.5em;
        }

        .pq-mb-5 {
          margin-bottom: 3.125em;
        }

        .pq-ml-auto {
          margin-left: auto;
        }
        .pq-mr-auto {
          margin-right: auto;
        }
        .pq-ml-0 {
          margin-left: 0;
        }
        .pq-ml-1 {
          margin-left: 0.625em;
        }

        .pq-ml-2 {
          margin-left: 1.25em;
        }

        .pq-ml-3 {
          margin-left: 1.875em;
        }

        .pq-ml-4 {
          margin-left: 2.5em;
        }

        .pq-ml-5 {
          margin-left: 3.125em;
        }

        .pq-pt-1 {
          padding-top: 0.625em;
        }

        .pq-pt-2 {
          padding-top: 1.25em;
        }

        .pq-pt-3 {
          padding-top: 1.875em;
        }

        .pq-pt-4 {
          padding-top: 2.5em;
        }

        .pq-pt-5 {
          padding-top: 3.125em;
        }

        .pq-pr-1 {
          padding-right: 0.625em;
        }

        .pq-pr-2 {
          padding-right: 1.25em;
        }

        .pq-pr-3 {
          padding-right: 1.875em;
        }

        .pq-pr-4 {
          padding-right: 2.5em;
        }

        .pq-pr-5 {
          padding-right: 3.125em;
        }

        .pq-pt-5 {
          padding-top: 3.125em;
        }

        .pq-pb-1 {
          padding-bottom: 0.625em;
        }

        .pq-pb-2 {
          padding-bottom: 1.25em;
        }

        .pq-pb-3 {
          padding-bottom: 1.875em;
        }

        .pq-pb-4 {
          padding-bottom: 2.5em;
        }

        .pq-pb-5 {
          padding-bottom: 3.125em;
        }

        .pq-pl-1 {
          padding-left: 0.625em;
        }

        .pq-pl-2 {
          padding-left: 1.25em;
        }

        .pq-pl-3 {
          padding-left: 1.875em;
        }

        .pq-pl-4 {
          padding-left: 2.5em;
        }

        .pq-pl-5 {
          padding-left: 3.125em;
        }

        /**
         * Typography
         */

        .pq-subhead {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }

        .-white {
          color: #ffffff;
        }

        .-black {
          color: #000000;
        }

        .pq-text-caption {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #8a91a5;
        }

        .pq-text-ui {
          font-size: 14px;
        }

        .pq-icon {
          width: 16px;
          height: 16px;
          display: inline-block;
          object-fit: contain;

          &.-large {
            width: 24px;
            height: 24px;
          }
        }

        .pq-icon {
          &.-notification {
            background-image: url(/static/svg/icons/notification.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-dropdown-down {
            background-image: url(/static/svg/icons/dropdown.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-disabled {
            opacity: 0.3;
            cursor: auto;
          }

          &.-dropdown-up {
            background-image: url(/static/svg/icons/dropdown.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            transform: rotate(180deg);
          }

          &.-arrow-left {
            background-image: url(/static/svg/icons/arrow.svg);
            background-repeat: no-repeat;
            background-position: 50%;
            transform: rotate(180deg);
          }

          &.-arrow-right {
            background-image: url(/static/svg/icons/arrow.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-white {
            filter: grayscale(1) brightness(10);
          }

          &.-gray {
            filter: brightness(0) saturate(100%) invert(92%) sepia(5%)
              saturate(74%) hue-rotate(202deg) brightness(106%) contrast(95%);
          }

          &.-dark-black {
            filter: invert(6%) sepia(34%) saturate(4815%) hue-rotate(223deg)
              brightness(91%) contrast(98%);
          }

          &.-black {
            filter: invert(17%) sepia(0%) saturate(132%) hue-rotate(173deg)
              brightness(86%) contrast(77%);
          }

          &.-red {
            filter: brightness(0) saturate(100%) invert(56%) sepia(99%)
              saturate(493%) hue-rotate(310deg) brightness(91%) contrast(131%);
          }

          &.-green {
            filter: invert(26%) sepia(65%) saturate(4362%) hue-rotate(151deg)
              brightness(98%) contrast(102%);
          }

          &.-primary-blue {
            filter: brightness(0) saturate(100%) invert(39%) sepia(71%)
              saturate(502%) hue-rotate(177deg) brightness(88%) contrast(89%);
          }

          &.-delete {
            background-image: url(/static/svg/icons/delete.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-plus {
            background-image: url(/static/svg/icons/plus.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-dots {
            background-image: url(/static/svg/icons/dots.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-link {
            background-image: url(/static/svg/icons/link.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-edit {
            background-image: url(/static/svg/icons/edit.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-history {
            background-image: url(/static/svg/icons/history.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-message {
            background-image: url(/static/svg/icons/message.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-profile {
            background-image: url(/static/svg/icons/profile.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-review {
            background-image: url(/static/svg/icons/review.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-well-done {
            background-image: url(/static/svg/icons/well-done.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-attention {
            background-image: url(/static/svg/icons/attention.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-align-left {
            background-image: url(/static/svg/icons/format-align-left.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-align-right {
            background-image: url(/static/svg/icons/format-align-right.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-align-justify {
            background-image: url(/static/svg/icons/format-align-justify.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-align-center {
            background-image: url(/static/svg/icons/format-align-center.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-italic {
            background-image: url(/static/svg/icons/format-italic.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-underline {
            background-image: url(/static/svg/icons/format-underline.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-format-bold {
            background-image: url(/static/svg/icons/format-bold.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-reply {
            background-image: url(/static/svg/icons/reply.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-attach {
            background-image: url(/static/svg/icons/attach.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-automate {
            background-image: url(/static/svg/icons/automate.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-print {
            background-image: url(/static/svg/icons/print.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-checkmark-bg {
            background-image: url(/static/svg/icons/checkmark-bg.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-checkmark-white {
            background-image: url(/static/svg/icons/checkmark-white.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-add-mark {
            background-image: url(/static/svg/icons/add.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-rating {
            background-image: url(/static/svg/icons/rating.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-scale {
            background-image: url(/static/svg/icons/scale.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-dropdown-square {
            background-image: url(/static/svg/icons/dropdown-square.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-text {
            background-image: url(/static/svg/icons/text.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-matrix {
            background-image: url(/static/svg/icons/matrix.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-list {
            background-image: url(/static/svg/icons/list.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-ranking {
            background-image: url(/static/svg/icons/ranking.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-blue-drop-up {
            background-image: url(/static/svg/icons/blue-drop-up.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-blue-drop-down {
            background-image: url(/static/svg/icons/blue-drop-down.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-black-drop-up {
            background-image: url(/static/svg/icons/black-drop-up.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }
          &.-black-drop-down {
            background-image: url(/static/svg/icons/black-drop-down.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-grey-drop-down {
            background-image: url(/static/svg/icons/grey-drop-down.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }
          &.-add-answer {
            background-image: url(/static/svg/icons/add-answer.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }
          &.-minus {
            background-image: url(/static/svg/icons/minus.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }
          &.-pencil {
            background-image: url(/static/svg/icons/edit.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }
          &.-small-dropdown {
            background-image: url(/static/svg/icons/small-dropdown.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-small-dropup {
            background-image: url(/static/svg/icons/small-dropup.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-audience {
            background-image: url(/static/svg/icons/people-map.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-process {
            background-image: url(/static/svg/icons/process.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-close {
            background-image: url(/static/svg/icons/close.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-trash-gray {
            background-image: url(/static/svg/icons/trash-gray.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-calendar {
            background-image: url(/static/svg/icons/calendar.svg);
            background-repeat: no-repeat;
            background-position: 50%;
          }

          &.-l {
            width: 64px;
            height: 64px;
          }

          &.-xl {
            width: 128px;
            height: 128px;
          }

          &.-m {
            width: 48px;
            height: 48px;
          }

          &.-s {
            width: 24px;
            height: 24px;
          }

          &.-xs {
            width: 16px;
            height: 16px;
          }

          &.-xxs {
            width: 12px;
            height: 12px;
          }

          &.-logo {
            background-repeat: no-repeat;
            background-position: 50%;
          }
        }

        .pq-badge {
          position: absolute;
          right: -50px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: solid 1px rgba(203, 208, 223, 0.3);
          background-color: #ffffff;
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: center;
          color: #8a91a5;
          z-index: 9999;

          &.-blue {
            background-color: #6594dd;
            color: #ffffff;
          }
        }

        .pq-badge-on {
          background-color: #ff696d;
          font-family: Poppins;
          font-size: 18px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
        }

        .pq-badge-completed {
          border: solid 1px rgba(203, 208, 223, 0.3);
          background-color: #1a3154;
          font-family: Poppins;
          font-size: 24px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          svg path:last-child {
            fill: #ffffff;
          }
        }

        .pq-error-message {
          width: 100%;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: right;
          color: #ff696d;
          height: 20px;
        }

        .pq-is-relative {
          position: relative;
        }

        .pq-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .pq-col {
          display: flex;
          flex-direction: column;
          flex-basis: 100%;
          flex: 1;
        }

        .pq-flex-align-start {
          justify-content: flex-start;
        }

        .pq-flex-shrink {
          flex: 0;
        }

        .pq-u-mb-s {
          margin-bottom: 12px;
        }

        .pq-button {
          text-align: center;
          font-size: 14px;
          font-weight: 600;
        }

        .pq-form {
          display: flex;
          flex-direction: column;
        }

        .pq-board-error {
          border: solid 2px rgba(255, 105, 109, 0.5);
        }

        .pq-text-align-center {
          text-align: center;
        }

        .pq-modal__overlay {
          position: fixed;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
          z-index: 9999;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(5, 15, 31, 0.3);
          opacity: 0;
          transition: all 0.5s ease-out;

          &.-lightbox {
            background-color: rgba(5, 15, 31, 0.6);
          }

          &.pq-modal__overlay--after-open {
            opacity: 1;
          }

          &.pq-modal__overlay--before-close {
            opacity: 0;
          }

          @media (max-height: 642px) {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
          }
        }

        @media print, screen and (min-width: 48em) {
          .pq-modal {
            margin: auto 0;
          }
        }

        .pq-surface {
          padding: 24px;
          background: white;

          *:last-child {
            margin-bottom: 0;
          }

          &.-rounded {
            border-radius: 16px;
          }

          &.-outline {
            border: 1px solid rgba(25, 25, 25, 0.2);
          }

          &.-shadow {
            box-shadow: 0 2px 16px 0 rgba(25, 25, 25, 0.16);
          }

          &.-deep-shadow {
            box-shadow: 0 2px 16px 0 rgba(25, 25, 25, 0.16);
          }

          a#{&},
          button#{&} {
            width: 100%;
            display: block;
            transition: box-shadow 0.3s;
            cursor: pointer;

            &:hover {
              box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);

              &.-outline {
                box-shadow: 0 2px 8px 0 rgba(25, 25, 25, 0.16);
              }
              &.-shadow {
                box-shadow: 0 2px 16px 0 rgba(25, 25, 25, 0.1);
              }
              &.-deep-shadow {
                box-shadow: 0 2px 32px 0 rgba(25, 25, 25, 0.16);
              }
            }
          }
        }

        @media print {
          .pq-sidebar {
            visibility: hidden;
          }
          .pq-header {
            visibility: hidden;
          }
          .pq-survey-header {
            visibility: hidden;
          }
          .pq-survey-builder-required-question {
            visibility: visible;
            position: absolute;
            top: 40px;
            left: 30px;
          }
          .pq-survey-builder-add-questions {
            visibility: visible;
            position: absolute;
            top: 1024px;
            left: 30px;
          }
        }

        ::-webkit-scrollbar {
          width: 4px;
        }

        ::-webkit-scrollbar-thumb {
          background-color: #cbd0df;
          border-radius: 4px;
        }

        .pq-tooltip__portal {
          position: absolute;
          z-index: 9999;

          .pq-arrow {
            position: absolute;
            border-color: transparent;
            border-style: solid;
          }
        }

        .pq-tooltip__container {
          color: #cbd0df;
          border-radius: 5px;
          padding: 5px 10px;
          font-size: 14px;
          line-height: 1.4;
          outline: 0;
          pointer-events: auto;

          &::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            bottom: -11px;
            left: 11px;
            transform: translate(-50%, -50%) rotate(45deg);
          }
        }

        // GLOBAL SELECT DROPDOWN STYLES
        // these must be here due to how the select menu is portaled to the root of the DOM
        .pq-select__option,
        .pq-multiselect__option {
          padding-left: 24px !important;
          border: solid 0px black !important;
          box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3) !important;
          display: flex !important;

          padding: 10px !important;
          box-shadow: inset 0 -1px 0 0 rgba(203, 208, 223, 0.3) !important;
          align-items: center !important;
          cursor: pointer !important;

          font-family: Poppins !important;
          font-size: 13px !important;
          font-weight: 500 !important;
          font-stretch: normal !important;
          font-style: normal !important;
          line-height: 1.54 !important;
          letter-spacing: normal !important;
          color: #0d1331 !important;

          &:hover {
            color: #3f6db4 !important;
            .pq-icon {
              filter: grayscale(0) !important;
            }
          }
          .pq-icon {
            filter: grayscale(1) !important;
          }

          &:last-of-type {
            box-shadow: none !important;
          }
        }
        .pq-multiselect__option--is-selected,
        .pq-select__option--is-selected {
          color: #3f6db4 !important;
          background-color: white !important;
        }

        .pq-multiselect__option--is-focused,
        .pq-select__option--is-focused {
          color: #3f6db4 !important;
          background-color: #eff3f9 !important;
        }

        .pq-select__menu-portal {
          z-index: 10000 !important;
        }

        .pq-multiselect__menu,
        .pq-select__menu {
          border-radius: 8px !important;
          box-shadow: 0 2px 16px 0 rgba(203, 208, 223, 0.4) !important;
        }
        .pq-multiselect__menu-list,
        .pq-select__menu-list {
          // max-height: 150px !important;
        }

        .pq-multiselect__indicator-separator,
        .pq-select__indicator-separator {
          display: none !important;
        }

        .pq-select__dropdown-indicator {
          padding-right: 12px !important;
        }

        .pq-multiselect__option-icon,
        .pq-select__option-icon {
          margin-right: 16px !important;
        }

        .pq-data-table-item {
          svg path:last-child {
            fill: #cbd0df !important;
          }
        }

        // form related css
        .pq-input-title {
          position: absolute;
          margin-left: 16px;
          margin-top: -10px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #8a91a5;
          background-color: white;
          white-space: nowrap;
          padding-left: 8px;
          padding-right: 8px;
        }

        .pq-input-board {
          width: 100%;
          height: 48px;
          border-radius: 8px;
          background-color: #ffffff;
          &.-disabled {
            opacity: 0.5;
          }
        }

        .pq-truncate {
          word-break: break-word;
          text-overflow: ellipsis;
          line-height: 16px; /* fallback */
          max-height: 32px; /* fallback */
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        .react-datepicker-popper {
          z-index: 3;
        }

        .-is-clickable {
          cursor: pointer;
        }

        .--bg-success {
          background-color: ${styleVars.green40Accent};
        }

        .--success {
          color: ${styleVars.green40Accent};
        }

        .pq-dot {
          margin-right: 6px;
          border-radius: 50%;
          height: 6px;
          width: 6px;
        }

        .no-scroll {
          overflow: hidden;
        }

        .pq-dashboard-main__h-padding {
          padding-left: 0 70px 0 30px;
        }

        ul.pq-list__disc {
          list-style: revert;
          padding: 0 1rem;

          li {
            list-style-type: disc;
          }
        }

        .pq-scrollbar__auto-hide {
          overflow: auto;
          mask-image: linear-gradient(to top, transparent, black),
            linear-gradient(to left, transparent 17px, black 17px);
          mask-size: 100% 20000px;
          mask-position: left bottom;
          transition:
            mask-position 0.3s,
            -webkit-mask-position 0.3s;

          &:hover {
            -webkit-mask-position: left top;
          }
        }
      `}
    />
  )
}

export default GlobalStyles
