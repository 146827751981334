/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ApolloQueryResult,
  Context,
  OperationVariables,
  QueryOptions,
} from '@palqee/apollo-client'
import {
  CompanyProcessCreateDocument,
  CompanyProcessCreateMutationVariables,
  CompanyProcessesGetDocument,
  SurveyAnswerOptionCreateDocument,
  SurveyAnswerOptionCreateMutationVariables,
  SurveyAnswerOptionDeleteDocument,
  SurveyAnswerOptionDeleteMutationVariables,
  SurveyAnswerOptionUpdateDocument,
  SurveyAnswerOptionUpdateMutationVariables,
  SurveyAudiencesSetDocument,
  SurveyAudiencesSetMutationVariables,
  SurveyCategoriesGetDocument,
  SurveyCategoriesGetQuery,
  SurveyCategoriesGetQueryVariables,
  SurveyCopyDocument,
  SurveyCopyMutationVariables,
  SurveyCreateDocument,
  SurveyCreateMutationVariables,
  SurveyDatamapResponsesGetDocument,
  SurveyDatamapResponsesGetQueryVariables,
  SurveyDeleteDocument,
  SurveyDeleteMutationVariables,
  SurveyGetDocument,
  SurveyGetQueryVariables,
  SurveyInvitationDeleteDocument,
  SurveyInvitationDeleteMutationVariables,
  SurveyInviteAudiencesDocument,
  SurveyInviteAudiencesMutationVariables,
  SurveyInviteUsersDocument,
  SurveyInviteUsersMutationVariables,
  SurveyQuestionCreateDocument,
  SurveyQuestionCreateMutationVariables,
  SurveyQuestionDeleteDocument,
  SurveyQuestionDeleteMutationVariables,
  SurveyQuestionTypesGetDocument,
  SurveyQuestionTypesGetQuery,
  SurveyQuestionTypesGetQueryVariables,
  SurveyQuestionUpdateDocument,
  SurveyQuestionUpdateMutationVariables,
  SurveyResponseCreateDocument,
  SurveyResponseCreateMutationVariables,
  SurveyResponseDeleteDocument,
  SurveyResponseDeleteMutationVariables,
  SurveyResponseDocumentUploadDocument,
  SurveyResponseDocumentUploadMutationVariables,
  SurveyResponsesGetDocument,
  SurveyResponsesGetQuery,
  SurveyResponsesGetQueryVariables,
  SurveyScheduleCreateDocument,
  SurveyScheduleCreateMutationVariables,
  SurveyScheduleDeleteDocument,
  SurveyScheduleDeleteMutationVariables,
  SurveySessionCreateDocument,
  SurveySessionCreateMutationVariables,
  SurveySessionDeleteDocument,
  SurveySessionDeleteMutationVariables,
  SurveySessionUpdateDocument,
  SurveySessionUpdateMutationVariables,
  SurveySubcategoryCreateFromTemplateDocument,
  SurveySubcategoryCreateFromTemplateMutationVariables,
  SurveySubcategoryDeleteDocument,
  SurveySubcategoryDeleteMutationVariables,
  SurveySubcategoryTemplatesGetDocument,
  SurveySubcategoryTemplatesGetQuery,
  SurveySubcategoryTemplatesGetQueryVariables,
  SurveyUpdateDocument,
  SurveyUpdateMutationVariables,
} from '@generated/types.d'
import {
  SurveySubcategoryCreateDocument,
  SurveySubcategoryCreateMutationVariables,
} from '@features/core/graphql/survey/mutation/__generated__/subcategory-create'

import { AfterClickNextProps } from '@common/components/modal/choose-audience-groups'
import { MutationOptions } from '@apollo/client/core/watchQueryOptions'
import { PqApolloClient } from './index'
import { SurveyGetBuilderDocument } from '@modules/products/surveys/builder/__generated__'
import { SurveyGetRespondentsSharedQueryVariables } from '@modules/products/surveys/respondents/__generated__'
import dayjs from 'dayjs'

type PqApiCall<T> = (
  client: PqApolloClient,
  variables?: any,
  graphqlDocument?: any,
) => Promise<T>

// @todo find MutationFetchResult type in new apollo-client v3
// type RefetchQuery = RefetchQueryDescription | ((result: any) => RefetchQueryDescription)

export const upsertQuestion = async (
  client: PqApolloClient,
  mutationVariables:
    | SurveyQuestionCreateMutationVariables
    | SurveyQuestionUpdateMutationVariables,
) => {
  return client.mutate({
    variables: mutationVariables,
    mutation: SurveyQuestionCreateDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyCategoriesGet: PqApiCall<
  ApolloQueryResult<SurveyCategoriesGetQuery>
> = async (
  client: PqApolloClient,
  variables: SurveyCategoriesGetQueryVariables,
  graphqlDocument: typeof SurveyCategoriesGetDocument = SurveyCategoriesGetDocument,
) => {
  const apiRes = await client.query({
    variables,
    query: graphqlDocument,
  })

  return apiRes
}

export const surveyQuestionTypesGet = async (
  client: PqApolloClient,
  variables: SurveyQuestionTypesGetQueryVariables,
  graphqlDocument: typeof SurveyQuestionTypesGetDocument = SurveyQuestionTypesGetDocument,
): Promise<ApolloQueryResult<SurveyQuestionTypesGetQuery>> => {
  const apiRes = await client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveyQuestionCreate = async (
  client: PqApolloClient,
  variables: SurveyQuestionCreateMutationVariables,
  graphqlDocument: typeof SurveyQuestionCreateDocument = SurveyQuestionCreateDocument,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveyQuestionUpdate = async (
  client: PqApolloClient,
  variables: SurveyQuestionUpdateMutationVariables,
  graphqlDocument: typeof SurveyQuestionUpdateDocument = SurveyQuestionUpdateDocument,
  options?: Partial<
    MutationOptions<any, SurveyQuestionUpdateMutationVariables>
  >,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
    ...options,
  })

  return apiRes
}

export const surveyQuestionDelete = async (
  client: PqApolloClient,
  variables: SurveyQuestionDeleteMutationVariables,
  graphqlDocument: typeof SurveyQuestionDeleteDocument = SurveyQuestionDeleteDocument,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
    refetchQueries: [
      {
        query: SurveyGetBuilderDocument,
      },
    ],
  })

  return apiRes
}

export const surveyAnswerOptionCreate = async (
  client: PqApolloClient,
  variables: SurveyAnswerOptionCreateMutationVariables,
  graphqlDocument: typeof SurveyAnswerOptionCreateDocument = SurveyAnswerOptionCreateDocument,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveyAnswerOptionUpdate = async (
  client: PqApolloClient,
  variables: SurveyAnswerOptionUpdateMutationVariables,
  graphqlDocument: typeof SurveyAnswerOptionUpdateDocument = SurveyAnswerOptionUpdateDocument,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveyAnswerOptionDelete = async (
  client: PqApolloClient,
  variables: SurveyAnswerOptionDeleteMutationVariables,
  graphqlDocument: typeof SurveyAnswerOptionDeleteDocument = SurveyAnswerOptionDeleteDocument,
) => {
  const apiRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveySubcategoryTemplatesGet: PqApiCall<
  ApolloQueryResult<SurveySubcategoryTemplatesGetQuery>
> = async (
  client: PqApolloClient,
  variables: SurveySubcategoryTemplatesGetQueryVariables,
  graphqlDocument: typeof SurveySubcategoryTemplatesGetDocument = SurveySubcategoryTemplatesGetDocument,
) => {
  const apiRes = await client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })

  return apiRes
}

export const surveyUpdate = async (
  client: PqApolloClient,
  variables: SurveyUpdateMutationVariables,
  graphqlDocument: typeof SurveyUpdateDocument = SurveyUpdateDocument,
  options?: Partial<MutationOptions<any, SurveyUpdateMutationVariables>>,
) => {
  const upsertSurveyRes = await client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })

  return upsertSurveyRes
}

export const surveyCopy = async (
  client: PqApolloClient,
  variables: SurveyCopyMutationVariables,
  graphqlDocument: typeof SurveyCopyDocument = SurveyCopyDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyScheduleCreate = async (
  client: PqApolloClient,
  variables: SurveyScheduleCreateMutationVariables,
  graphqlDocument: typeof SurveyScheduleCreateDocument = SurveyScheduleCreateDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyScheduleDelete = async (
  client: PqApolloClient,
  variables: SurveyScheduleDeleteMutationVariables,
  graphqlDocument: typeof SurveyScheduleDeleteDocument = SurveyScheduleDeleteDocument,
  refetchQueries?: any,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    refetchQueries,
    awaitRefetchQueries: true,
  })
}

export const surveySessionCreate = async (
  client: PqApolloClient,
  variables: SurveySessionCreateMutationVariables,
  graphqlDocument: typeof SurveySessionCreateDocument = SurveySessionCreateDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveySessionDelete = async (
  client: PqApolloClient,
  variables: SurveySessionDeleteMutationVariables,
  graphqlDocument: typeof SurveySessionDeleteDocument = SurveySessionDeleteDocument,
  options?: Partial<MutationOptions<any, SurveySessionDeleteMutationVariables>>,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })
}
export const surveySessionUpdate = async (
  client: PqApolloClient,
  variables: SurveySessionUpdateMutationVariables,
  graphqlDocument: typeof SurveySessionUpdateDocument = SurveySessionUpdateDocument,
  options?: Partial<MutationOptions<any, SurveySessionUpdateMutationVariables>>,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })
}

export const surveyAudiencesSet = async (
  client: PqApolloClient,
  variables: SurveyAudiencesSetMutationVariables,
  graphqlDocument: typeof SurveyAudiencesSetDocument = SurveyAudiencesSetDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyInviteUsers = async (
  client: PqApolloClient,
  variables: SurveyInviteUsersMutationVariables,
  graphqlDocument: typeof SurveyInviteUsersDocument = SurveyInviteUsersDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyInviteAudiences = async (
  client: PqApolloClient,
  variables: SurveyInviteAudiencesMutationVariables,
  graphqlDocument: typeof SurveyInviteAudiencesDocument = SurveyInviteAudiencesDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyDevelopmentCopyAndCreateSchedule = async (
  client: PqApolloClient,
  surveyCopyVars: SurveyCopyMutationVariables,
  periodDays: number,
  selectedInvitationIds: AfterClickNextProps[],
  companyId: string,
) => {
  const startAtTimeStamp = dayjs().format()
  // copy the template
  const surveyCopyRes = await surveyCopy(client, surveyCopyVars)
  const newSurvey = surveyCopyRes?.data?.survey?.copy?.survey

  // schedule the survey
  await surveyScheduleCreate(client, {
    surveyId: newSurvey.id,
    startAt: startAtTimeStamp,
    defaultSessionDurationDays: periodDays,
  })

  // if startDate is today, create the sessions (which they all are atm)
  const surveySessionCreateRes = await surveySessionCreate(client, {
    surveyId: newSurvey.id,
    startedAt: startAtTimeStamp,
    finishedAt: dayjs().add(periodDays, 'day').format(),
    deadlineAt: dayjs().add(periodDays, 'day').format(),
  })

  const getIdsByType = (filter) => {
    return selectedInvitationIds
      .filter(filter)
      .map((invitation) => invitation?.invitationId)
  }
  const selectedAudienceIds = getIdsByType(
    (invitation) => !invitation?.isSingleUser,
  )

  if (selectedInvitationIds.some((invitation) => !invitation?.isSingleUser)) {
    // attach the audiences to survey
    await surveyAudiencesSet(client, {
      surveyId: newSurvey.id,
      audienceIds: selectedAudienceIds,
    })

    await surveyInviteAudiences(client, {
      sessionId:
        surveySessionCreateRes?.data?.survey?.sessionCreate?.session?.id,
      audienceIds: selectedAudienceIds,
      companyId: String(companyId),
    })
  } else if (
    selectedInvitationIds.some((invitation) => invitation?.isSingleUser)
  ) {
    await surveyInviteUsers(client, {
      userIds: getIdsByType((invitation) => invitation?.isSingleUser),
      sessionId:
        surveySessionCreateRes?.data?.survey?.sessionCreate?.session?.id,
    })
  }

  // upsert the survey
  // currently errors when subcategories exist
  await surveyUpdate(client, {
    surveyId: newSurvey.id,
    isDraft: false,
    isTemplate: false,
  })

  return surveySessionCreateRes
}

/**
 * General query to get surveys
 *
 * @param client apollo client
 * @param variables query params
 * @param graphqlDocument GQL query
 * @param options additional options to be sent
 */
export const surveyGet = async (
  client: PqApolloClient,
  variables:
    | SurveyGetQueryVariables
    | SurveyGetRespondentsSharedQueryVariables
    | any,
  graphqlDocument: typeof SurveyGetDocument = SurveyGetDocument,
  options?: Partial<QueryOptions<OperationVariables>>,
) => {
  return client.query({
    ...options,
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyResponseCreate = async (
  client: PqApolloClient,
  variables: SurveyResponseCreateMutationVariables,
  graphqlDocument: typeof SurveyResponseCreateDocument = SurveyResponseCreateDocument,
  refetchQueries?: any,
  context?: Context,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
    refetchQueries,
    context,
  })
}

export const surveyResponseDelete = async (
  client: PqApolloClient,
  variables: SurveyResponseDeleteMutationVariables,
  graphqlDocument: typeof SurveyResponseDeleteDocument = SurveyResponseDeleteDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
export const surveySubcategoryCreateFromTemplate = (
  client: PqApolloClient,
  variables: SurveySubcategoryCreateFromTemplateMutationVariables,
  graphqlDocument: typeof SurveySubcategoryCreateFromTemplateDocument = SurveySubcategoryCreateFromTemplateDocument,
  options?: Partial<
    MutationOptions<any, SurveySubcategoryCreateFromTemplateMutationVariables>
  >,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })
}
export const surveySubcategoryDelete = (
  client: PqApolloClient,
  variables: SurveySubcategoryDeleteMutationVariables,
  graphqlDocument: typeof SurveySubcategoryDeleteDocument = SurveySubcategoryDeleteDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
  })
}

export const surveyDelete = (
  client: PqApolloClient,
  variables: SurveyDeleteMutationVariables,
  graphqlDocument: typeof SurveyDeleteDocument = SurveyDeleteDocument,
  options?: Partial<MutationOptions<any, SurveyUpdateMutationVariables>>,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })
}
export const surveyCreate = (
  client: PqApolloClient,
  variables: SurveyCreateMutationVariables,
  graphqlDocument: typeof SurveyCreateDocument = SurveyCreateDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    refetchQueries: ['SurveyGet', 'SurveyGetBuilder'],
  })
}
export const surveyBusinessProcessCreate = (
  client: PqApolloClient,
  variables: CompanyProcessCreateMutationVariables,
  graphqlDocument: typeof CompanyProcessCreateDocument = CompanyProcessCreateDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
  })
}
export const surveyProcessesGet: PqApiCall<ApolloQueryResult<any>> = async (
  client: PqApolloClient,
  variables: any,
  graphqlDocument: typeof CompanyProcessesGetDocument = CompanyProcessesGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
export const surveyResponsesGet: PqApiCall<
  ApolloQueryResult<SurveyResponsesGetQuery>
> = async (
  client: PqApolloClient,
  variables: SurveyResponsesGetQueryVariables,
  graphqlDocument: typeof SurveyResponsesGetDocument = SurveyResponsesGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyResponseDocumentUpload = async (
  client: PqApolloClient,
  variables: SurveyResponseDocumentUploadMutationVariables,
  graphqlDocument: typeof SurveyResponseDocumentUploadDocument = SurveyResponseDocumentUploadDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
    context: {
      hasUpload: true,
    },
  })
}

export const surveyDatamapResponsesGet = async (
  client: PqApolloClient,
  variables: SurveyDatamapResponsesGetQueryVariables,
  graphqlDocument: typeof SurveyDatamapResponsesGetDocument = SurveyDatamapResponsesGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveySubcategoryCreate = async (
  client: PqApolloClient,
  variables: SurveySubcategoryCreateMutationVariables,
  graphqlDocument: typeof SurveySubcategoryCreateDocument = SurveySubcategoryCreateDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const surveyInvitationDelete = async (
  client: PqApolloClient,
  variables: SurveyInvitationDeleteMutationVariables,
  graphqlDocument: typeof SurveyInvitationDeleteDocument = SurveyInvitationDeleteDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
