import { ApolloClient } from '@palqee/apollo-client'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { TApolloClient } from '@palqee/apollo-client'

export * from './auth'
export * from './user'
export * from './general'
export * from './company'
export * from './survey'
export * from './statistics'
export * from './document'

export type PqApolloClient = TApolloClient | ApolloClient<any>
