import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import { MaturityScoreAveragesFragmentDoc } from '../mutation/__generated__/maturity-score-set'
export type SurveyDetailsFragment = {
  __typename: 'Survey'
  id: string
  surveyId: string
  name: string
  questionsCount: any
  isDraft: boolean
  isTemplate: boolean
  type: Types.SurveyType
  createdAt: any
  lastUsedAt?: any | null
  sessions: Array<{
    __typename: 'SurveySession'
    id: string
    finishedAt: any
    deadlineAt?: any | null
  }>
  subcategories: Array<{
    __typename: 'SurveySubcategory'
    id: string
    header: string
    uiCode?: any | null
    description?: string | null
    isGuided: boolean
    sortOrder?: any | null
    questionsCount: any
    questions: Array<{
      __typename: 'SurveyQuestion'
      id: string
      uiCode?: any | null
      modifiers?: any | null
      label: string
      description?: string | null
      subcategoryId: string
      sortOrder?: any | null
      datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      guidedStepId?: string | null
      questionTemplateId?: string | null
      isTemplateQuestion?: boolean | null
      answerOptions: Array<
        | {
            __typename: 'DatamapAnswerOption'
            id: string
            isSensitive?: boolean | null
            isCustom: boolean
            value?: string | null
            datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
            sortOrder?: any | null
            entity?:
              | {
                  __typename: 'Asset'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataElement'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataLifecycle'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataOwnership'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataSharing'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataSource'
                  id: string
                  needsValue: boolean
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'DataSubject'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'Department'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'LegalBasis'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'Process'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'ProcessingType'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'Region'
                  id: string
                  name: string
                  uiCode?: any | null
                }
              | {
                  __typename: 'SurveyAnswerOption'
                  id: string
                  name?: string | null
                  uiCode?: any | null
                }
              | null
          }
        | {
            __typename: 'DatamapCustomAnswerOption'
            uiCode?: any | null
            value?: string | null
            sortOrder?: any | null
            isSensitive?: boolean | null
            isCustom: boolean
            datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
          }
        | {
            __typename: 'SurveyAnswerOption'
            id: string
            value?: string | null
            uiCode?: any | null
            sortOrder?: any | null
          }
        | { __typename: 'SurveyVirtualAnswerOption' }
      >
      type: {
        __typename: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
    }>
    guidedSteps?: Array<{
      __typename: 'SurveyGuidedStep'
      id: string
      name: string
      description?: string | null
      sortOrder?: any | null
    }> | null
    subcategoryTemplate?: {
      __typename: 'SurveySubcategoryTemplate'
      id: string
      header: string
      isSelected?: boolean | null
      isConfirmed?: boolean | null
      description?: string | null
      sortOrder: any
      questionTemplatesCount: any
      category: {
        __typename: 'SurveyCategory'
        id: string
        regulation?: {
          __typename: 'Regulation'
          id: string
          name: string
          uiCode?: any | null
        } | null
      }
    } | null
  }>
}

export type SurveySubcategoryTemplateDetailsFragment = {
  __typename: 'SurveySubcategoryTemplate'
  id: string
  uiCode?: any | null
  isSelected?: boolean | null
  isConfirmed?: boolean | null
  isActive: boolean
  isGuided: boolean
  header: string
  description?: string | null
  sortOrder: any
  questionTemplatesCount: any
  questionTemplates: Array<{
    __typename: 'SurveyQuestionTemplate'
    id: string
    uiCode?: any | null
    modifiers?: any | null
    label: string
    description?: string | null
    sortOrder: any
    answerOptionTemplates?: Array<{
      __typename: 'SurveyAnswerOptionTemplate'
      id: string
      value?: string | null
    }> | null
    type: {
      __typename: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
  }>
  category: {
    __typename: 'SurveyCategory'
    id: string
    regulation?: {
      __typename: 'Regulation'
      id: string
      name: string
      uiCode?: any | null
    } | null
  }
}

export type GuidedStepsDetailsFragment = {
  __typename: 'SurveyGuidedStep'
  id: string
  name: string
  description?: string | null
  sortOrder?: any | null
}

export type SurveySubcategoryDetailsFragment = {
  __typename: 'SurveySubcategory'
  id: string
  header: string
  uiCode?: any | null
  description?: string | null
  isGuided: boolean
  sortOrder?: any | null
  questionsCount: any
  questions: Array<{
    __typename: 'SurveyQuestion'
    id: string
    uiCode?: any | null
    modifiers?: any | null
    label: string
    description?: string | null
    subcategoryId: string
    sortOrder?: any | null
    datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    guidedStepId?: string | null
    questionTemplateId?: string | null
    isTemplateQuestion?: boolean | null
    answerOptions: Array<
      | {
          __typename: 'DatamapAnswerOption'
          id: string
          isSensitive?: boolean | null
          isCustom: boolean
          value?: string | null
          datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
          sortOrder?: any | null
          entity?:
            | {
                __typename: 'Asset'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataElement'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataLifecycle'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataOwnership'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataSharing'
                id: string
                needsValue: boolean
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataSource'
                id: string
                needsValue: boolean
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'DataSubject'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'Department'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'LegalBasis'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'Process'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'ProcessingType'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'Region'
                id: string
                name: string
                uiCode?: any | null
              }
            | {
                __typename: 'SurveyAnswerOption'
                id: string
                name?: string | null
                uiCode?: any | null
              }
            | null
        }
      | {
          __typename: 'DatamapCustomAnswerOption'
          uiCode?: any | null
          value?: string | null
          sortOrder?: any | null
          isSensitive?: boolean | null
          isCustom: boolean
          datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
        }
      | {
          __typename: 'SurveyAnswerOption'
          id: string
          value?: string | null
          uiCode?: any | null
          sortOrder?: any | null
        }
      | { __typename: 'SurveyVirtualAnswerOption' }
    >
    type: {
      __typename: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
  }>
  guidedSteps?: Array<{
    __typename: 'SurveyGuidedStep'
    id: string
    name: string
    description?: string | null
    sortOrder?: any | null
  }> | null
  subcategoryTemplate?: {
    __typename: 'SurveySubcategoryTemplate'
    id: string
    header: string
    isSelected?: boolean | null
    isConfirmed?: boolean | null
    description?: string | null
    sortOrder: any
    questionTemplatesCount: any
    category: {
      __typename: 'SurveyCategory'
      id: string
      regulation?: {
        __typename: 'Regulation'
        id: string
        name: string
        uiCode?: any | null
      } | null
    }
  } | null
}

export type ISurveyAnswerOptionDetails_DatamapAnswerOption_Fragment = {
  __typename: 'DatamapAnswerOption'
  id: string
  isSensitive?: boolean | null
  isCustom: boolean
  value?: string | null
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  sortOrder?: any | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type ISurveyAnswerOptionDetails_DatamapCustomAnswerOption_Fragment = {
  __typename: 'DatamapCustomAnswerOption'
  uiCode?: any | null
  value?: string | null
  sortOrder?: any | null
  isSensitive?: boolean | null
  isCustom: boolean
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
}

export type ISurveyAnswerOptionDetails_SurveyAnswerOption_Fragment = {
  __typename: 'SurveyAnswerOption'
  id: string
  value?: string | null
  uiCode?: any | null
  sortOrder?: any | null
}

export type ISurveyAnswerOptionDetails_SurveyVirtualAnswerOption_Fragment = {
  __typename: 'SurveyVirtualAnswerOption'
}

export type ISurveyAnswerOptionDetailsFragment =
  | ISurveyAnswerOptionDetails_DatamapAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_DatamapCustomAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_SurveyAnswerOption_Fragment
  | ISurveyAnswerOptionDetails_SurveyVirtualAnswerOption_Fragment

export type DatamapEntityDetails_Asset_Fragment = {
  __typename: 'Asset'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataElement_Fragment = {
  __typename: 'DataElement'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataLifecycle_Fragment = {
  __typename: 'DataLifecycle'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataOwnership_Fragment = {
  __typename: 'DataOwnership'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataSharing_Fragment = {
  __typename: 'DataSharing'
  id: string
  needsValue: boolean
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataSource_Fragment = {
  __typename: 'DataSource'
  id: string
  needsValue: boolean
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_DataSubject_Fragment = {
  __typename: 'DataSubject'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_Department_Fragment = {
  __typename: 'Department'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_LegalBasis_Fragment = {
  __typename: 'LegalBasis'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_Process_Fragment = {
  __typename: 'Process'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_ProcessingType_Fragment = {
  __typename: 'ProcessingType'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_Region_Fragment = {
  __typename: 'Region'
  id: string
  name: string
  uiCode?: any | null
}

export type DatamapEntityDetails_SurveyAnswerOption_Fragment = {
  __typename: 'SurveyAnswerOption'
  id: string
  name?: string | null
  uiCode?: any | null
}

export type DatamapEntityDetailsFragment =
  | DatamapEntityDetails_Asset_Fragment
  | DatamapEntityDetails_DataElement_Fragment
  | DatamapEntityDetails_DataLifecycle_Fragment
  | DatamapEntityDetails_DataOwnership_Fragment
  | DatamapEntityDetails_DataSharing_Fragment
  | DatamapEntityDetails_DataSource_Fragment
  | DatamapEntityDetails_DataSubject_Fragment
  | DatamapEntityDetails_Department_Fragment
  | DatamapEntityDetails_LegalBasis_Fragment
  | DatamapEntityDetails_Process_Fragment
  | DatamapEntityDetails_ProcessingType_Fragment
  | DatamapEntityDetails_Region_Fragment
  | DatamapEntityDetails_SurveyAnswerOption_Fragment

export type DatamapAnswerOptionDetailsFragment = {
  __typename: 'DatamapAnswerOption'
  id: string
  isSensitive?: boolean | null
  isCustom: boolean
  value?: string | null
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  sortOrder?: any | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapCustomAnswerOptionDetailsFragment = {
  __typename: 'DatamapCustomAnswerOption'
  uiCode?: any | null
  value?: string | null
  sortOrder?: any | null
  isSensitive?: boolean | null
  isCustom: boolean
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
}

export type SurveyAnswerOptionDetailsFragment = {
  __typename: 'SurveyAnswerOption'
  id: string
  value?: string | null
  uiCode?: any | null
  sortOrder?: any | null
}

export type SurveyQuestionDetailsFragment = {
  __typename: 'SurveyQuestion'
  id: string
  uiCode?: any | null
  modifiers?: any | null
  label: string
  description?: string | null
  subcategoryId: string
  sortOrder?: any | null
  datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  guidedStepId?: string | null
  questionTemplateId?: string | null
  isTemplateQuestion?: boolean | null
  answerOptions: Array<
    | {
        __typename: 'DatamapAnswerOption'
        id: string
        isSensitive?: boolean | null
        isCustom: boolean
        value?: string | null
        datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
        sortOrder?: any | null
        entity?:
          | {
              __typename: 'Asset'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataElement'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataLifecycle'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataOwnership'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataSharing'
              id: string
              needsValue: boolean
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataSource'
              id: string
              needsValue: boolean
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'DataSubject'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'Department'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'LegalBasis'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'Process'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'ProcessingType'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'Region'
              id: string
              name: string
              uiCode?: any | null
            }
          | {
              __typename: 'SurveyAnswerOption'
              id: string
              name?: string | null
              uiCode?: any | null
            }
          | null
      }
    | {
        __typename: 'DatamapCustomAnswerOption'
        uiCode?: any | null
        value?: string | null
        sortOrder?: any | null
        isSensitive?: boolean | null
        isCustom: boolean
        datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
      }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        value?: string | null
        uiCode?: any | null
        sortOrder?: any | null
      }
    | { __typename: 'SurveyVirtualAnswerOption' }
  >
  type: {
    __typename: 'QuestionType'
    id: string
    name: string
    code: string
    availableModifiers?: any | null
    isActive: boolean
    uiCode: any
  }
}

export type QuestionTemplateDetailsFragment = {
  __typename: 'SurveyQuestionTemplate'
  id: string
  uiCode?: any | null
  modifiers?: any | null
  label: string
  description?: string | null
  sortOrder: any
  answerOptionTemplates?: Array<{
    __typename: 'SurveyAnswerOptionTemplate'
    id: string
    value?: string | null
  }> | null
  type: {
    __typename: 'QuestionType'
    id: string
    name: string
    code: string
    availableModifiers?: any | null
    isActive: boolean
    uiCode: any
  }
}

export type AudienceDetailsFragment = {
  __typename: 'Audience'
  id: string
  name: string
  description?: string | null
  isSingleUser: boolean
  updatedAt?: any | null
  createdAt: any
  computingMethod: Types.CompanyAudienceComputingMethodEnum
  users: Array<{
    __typename: 'User'
    id: string
    userId: string
    firstName?: string | null
    lastName?: string | null
    email: any
    status: Types.UserStatusEnum
    roles: Array<{
      __typename: 'UserRole'
      userId: string
      roleId: string
      name: string
      companyId?: string | null
      code: Types.AuthRoleCodeEnum
    }>
    positions: Array<{
      __typename: 'UserPosition'
      position: {
        __typename: 'Position'
        id: string
        name: string
        companyId: string
      }
      department: {
        __typename: 'Department'
        id: string
        name: string
        companyId: string
      }
    }>
  }>
}

export type AudienceUserDetailsFragment = {
  __typename: 'User'
  id: string
  userId: string
  firstName?: string | null
  lastName?: string | null
  email: any
  status: Types.UserStatusEnum
  roles: Array<{
    __typename: 'UserRole'
    userId: string
    roleId: string
    name: string
    companyId?: string | null
    code: Types.AuthRoleCodeEnum
  }>
  positions: Array<{
    __typename: 'UserPosition'
    position: {
      __typename: 'Position'
      id: string
      name: string
      companyId: string
    }
    department: {
      __typename: 'Department'
      id: string
      name: string
      companyId: string
    }
  }>
}

export type ResponseDetailsFragment = {
  __typename: 'SurveyResponse'
  id: string
  value?: string | null
  answeredAt: any
  respondentId: string
  files: Array<{
    __typename: 'BinaryFileInfo'
    id: string
    originalFilename?: string | null
  }>
  answerOption?:
    | { __typename: 'DatamapAnswerOption'; id: string }
    | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
    | { __typename: 'SurveyAnswerOption'; id: string }
    | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
    | null
}

export type DatamapResponseResponseCommonFragment = {
  __typename: 'SurveyResponse'
  id: string
  value?: string | null
  respondentId: string
  questionId: string
  question: { __typename: 'SurveyQuestion'; id: string; sortOrder?: any | null }
}

export type DatamapResponseResponseDetailsFragment = {
  __typename: 'SurveyResponse'
  id: string
  value?: string | null
  respondentId: string
  questionId: string
  answerOption?:
    | { __typename: 'DatamapAnswerOption'; id: string }
    | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
    | { __typename: 'SurveyAnswerOption'; id: string }
    | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
    | null
  question: {
    __typename: 'SurveyQuestion'
    id: string
    modifiers?: any | null
    datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    sortOrder?: any | null
    type: {
      __typename: 'QuestionType'
      id: string
      name: string
      code: string
      availableModifiers?: any | null
      isActive: boolean
      uiCode: any
    }
    guidedStep?: {
      __typename: 'SurveyGuidedStep'
      id: string
      sortOrder?: any | null
    } | null
  }
}

export type SurveyManagerDatamapResponseGet_DatamapDataElementResponse_Fragment =
  {
    __typename: 'DatamapDataElementResponse'
    id: string
    isCustom: boolean
    isSensitive?: boolean | null
    responseId: string
    parentResponseId: string
    datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
    datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    response: {
      __typename: 'SurveyResponse'
      id: string
      value?: string | null
      questionId: string
      respondentId: string
      question: {
        __typename: 'SurveyQuestion'
        id: string
        sortOrder?: any | null
      }
    }
    entity?:
      | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'DataElement'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataLifecycle'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataOwnership'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSharing'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSource'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSubject'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'Department'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'LegalBasis'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'ProcessingType'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'SurveyAnswerOption'
          id: string
          name?: string | null
          uiCode?: any | null
        }
      | null
  }

export type SurveyManagerDatamapResponseGet_DatamapDataSharingResponse_Fragment =
  {
    __typename: 'DatamapDataSharingResponse'
    id: string
    sharedWith3rdParty?: string | null
    responseId: string
    parentResponseId: string
    datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
    datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    response: {
      __typename: 'SurveyResponse'
      id: string
      value?: string | null
      questionId: string
      respondentId: string
      question: {
        __typename: 'SurveyQuestion'
        id: string
        sortOrder?: any | null
      }
    }
    sharedWithDepartment?: {
      __typename: 'Department'
      id: string
      name: string
      uiCode?: any | null
    } | null
    entity?:
      | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'DataElement'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataLifecycle'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataOwnership'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSharing'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSource'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSubject'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'Department'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'LegalBasis'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'ProcessingType'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'SurveyAnswerOption'
          id: string
          name?: string | null
          uiCode?: any | null
        }
      | null
  }

export type SurveyManagerDatamapResponseGet_DatamapDataSourceResponse_Fragment =
  {
    __typename: 'DatamapDataSourceResponse'
    id: string
    providedBy3rdParty?: string | null
    isCustom: boolean
    responseId: string
    parentResponseId: string
    datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
    datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
    response: {
      __typename: 'SurveyResponse'
      id: string
      value?: string | null
      questionId: string
      respondentId: string
      question: {
        __typename: 'SurveyQuestion'
        id: string
        sortOrder?: any | null
      }
    }
    providedByDepartment?: {
      __typename: 'Department'
      id: string
      name: string
      uiCode?: any | null
    } | null
    entity?:
      | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'DataElement'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataLifecycle'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataOwnership'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSharing'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSource'
          id: string
          needsValue: boolean
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'DataSubject'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'Department'
          id: string
          name: string
          uiCode?: any | null
        }
      | {
          __typename: 'LegalBasis'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'ProcessingType'
          id: string
          name: string
          uiCode?: any | null
        }
      | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
      | {
          __typename: 'SurveyAnswerOption'
          id: string
          name?: string | null
          uiCode?: any | null
        }
      | null
  }

export type SurveyManagerDatamapResponseGet_DatamapResponse_Fragment = {
  __typename: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    questionId: string
    respondentId: string
    question: {
      __typename: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type SurveyManagerDatamapResponseGetFragment =
  | SurveyManagerDatamapResponseGet_DatamapDataElementResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapDataSharingResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapDataSourceResponse_Fragment
  | SurveyManagerDatamapResponseGet_DatamapResponse_Fragment

export type DatamapResponseGet_DatamapDataElementResponse_Fragment = {
  __typename: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseGet_DatamapDataSharingResponse_Fragment = {
  __typename: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  sharedWithDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseGet_DatamapDataSourceResponse_Fragment = {
  __typename: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  providedByDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseGet_DatamapResponse_Fragment = {
  __typename: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    question: {
      __typename: 'SurveyQuestion'
      id: string
      sortOrder?: any | null
    }
  }
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseGetFragment =
  | DatamapResponseGet_DatamapDataElementResponse_Fragment
  | DatamapResponseGet_DatamapDataSharingResponse_Fragment
  | DatamapResponseGet_DatamapDataSourceResponse_Fragment
  | DatamapResponseGet_DatamapResponse_Fragment

export type DatamapResponseCommon_DatamapDataElementResponse_Fragment = {
  __typename: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseCommon_DatamapDataSharingResponse_Fragment = {
  __typename: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  sharedWithDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseCommon_DatamapDataSourceResponse_Fragment = {
  __typename: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  providedByDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseCommon_DatamapResponse_Fragment = {
  __typename: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseCommonFragment =
  | DatamapResponseCommon_DatamapDataElementResponse_Fragment
  | DatamapResponseCommon_DatamapDataSharingResponse_Fragment
  | DatamapResponseCommon_DatamapDataSourceResponse_Fragment
  | DatamapResponseCommon_DatamapResponse_Fragment

export type DatamapResponseDetails_DatamapDataElementResponse_Fragment = {
  __typename: 'DatamapDataElementResponse'
  id: string
  isCustom: boolean
  isSensitive?: boolean | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename: 'DatamapAnswerOption'; id: string }
      | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename: 'SurveyAnswerOption'; id: string }
      | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseDetails_DatamapDataSharingResponse_Fragment = {
  __typename: 'DatamapDataSharingResponse'
  id: string
  sharedWith3rdParty?: string | null
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename: 'DatamapAnswerOption'; id: string }
      | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename: 'SurveyAnswerOption'; id: string }
      | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  sharedWithDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseDetails_DatamapDataSourceResponse_Fragment = {
  __typename: 'DatamapDataSourceResponse'
  id: string
  providedBy3rdParty?: string | null
  isCustom: boolean
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename: 'DatamapAnswerOption'; id: string }
      | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename: 'SurveyAnswerOption'; id: string }
      | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  providedByDepartment?: {
    __typename: 'Department'
    id: string
    name: string
    uiCode?: any | null
  } | null
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseDetails_DatamapResponse_Fragment = {
  __typename: 'DatamapResponse'
  id: string
  responseId: string
  parentResponseId: string
  datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
  datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
  response: {
    __typename: 'SurveyResponse'
    id: string
    value?: string | null
    respondentId: string
    questionId: string
    answerOption?:
      | { __typename: 'DatamapAnswerOption'; id: string }
      | { __typename: 'DatamapCustomAnswerOption'; id?: string | null }
      | { __typename: 'SurveyAnswerOption'; id: string }
      | { __typename: 'SurveyVirtualAnswerOption'; id?: string | null }
      | null
    question: {
      __typename: 'SurveyQuestion'
      id: string
      modifiers?: any | null
      datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
      sortOrder?: any | null
      type: {
        __typename: 'QuestionType'
        id: string
        name: string
        code: string
        availableModifiers?: any | null
        isActive: boolean
        uiCode: any
      }
      guidedStep?: {
        __typename: 'SurveyGuidedStep'
        id: string
        sortOrder?: any | null
      } | null
    }
  }
  entity?:
    | { __typename: 'Asset'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'DataElement'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataLifecycle'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataOwnership'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSharing'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSource'
        id: string
        needsValue: boolean
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'DataSubject'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'Department'
        id: string
        name: string
        uiCode?: any | null
      }
    | {
        __typename: 'LegalBasis'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Process'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'ProcessingType'
        id: string
        name: string
        uiCode?: any | null
      }
    | { __typename: 'Region'; id: string; name: string; uiCode?: any | null }
    | {
        __typename: 'SurveyAnswerOption'
        id: string
        name?: string | null
        uiCode?: any | null
      }
    | null
}

export type DatamapResponseDetailsFragment =
  | DatamapResponseDetails_DatamapDataElementResponse_Fragment
  | DatamapResponseDetails_DatamapDataSharingResponse_Fragment
  | DatamapResponseDetails_DatamapDataSourceResponse_Fragment
  | DatamapResponseDetails_DatamapResponse_Fragment

export type SurveyGetBuilderFragment = {
  __typename: 'Survey'
  id: string
  surveyId: string
  name: string
  questionsCount: any
  isDraft: boolean
  isTemplate: boolean
  type: Types.SurveyType
  createdAt: any
  lastUsedAt?: any | null
  sessions: Array<{
    __typename: 'SurveySession'
    id: string
    finishedAt: any
    deadlineAt?: any | null
  }>
}

export type SurveyManagerViewAnswersSessionFragment = {
  __typename: 'SurveySession'
  id: string
  startedAt: any
  finishedAt: any
  deadlineAt?: any | null
  isCompleted: boolean
  maturityScoreAveragesForSession?: {
    __typename: 'StatisticsMaturityScoreAveragesItem'
    sessionId?: string | null
    maturityScoreSum?: any | null
    maturityScoreAverage?: number | null
    maturityScoreAverageSum?: number | null
    surveysCount: any
    questionsCount: any
  } | null
  invitedUsers: Array<{ __typename: 'User'; id: string }>
  survey: {
    __typename: 'Survey'
    id: string
    name: string
    type: Types.SurveyType
    questionsCount: any
    audiences: Array<{ __typename: 'Audience'; id: string }>
    company: { __typename: 'Company'; id: string }
    categories: Array<{
      __typename: 'SurveyCategory'
      id: string
      name?: string | null
      regulation?: {
        __typename: 'Regulation'
        id: string
        name: string
        uiCode?: any | null
      } | null
    }>
    subcategories: Array<{
      __typename: 'SurveySubcategory'
      id: string
      header: string
      description?: string | null
      sortOrder?: any | null
      isGuided: boolean
      subcategoryTemplate?: {
        __typename: 'SurveySubcategoryTemplate'
        id: string
        category: {
          __typename: 'SurveyCategory'
          id: string
          name?: string | null
          regulation?: {
            __typename: 'Regulation'
            id: string
            name: string
            uiCode?: any | null
          } | null
        }
      } | null
      guidedSteps?: Array<{
        __typename: 'SurveyGuidedStep'
        id: string
        name: string
        description?: string | null
        sortOrder?: any | null
        questions: Array<{
          __typename: 'SurveyQuestion'
          maturityScore?: any | null
          id: string
          uiCode?: any | null
          modifiers?: any | null
          label: string
          description?: string | null
          subcategoryId: string
          sortOrder?: any | null
          datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
          datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
          guidedStepId?: string | null
          questionTemplateId?: string | null
          isTemplateQuestion?: boolean | null
          answerOptions: Array<
            | {
                __typename: 'DatamapAnswerOption'
                id: string
                isSensitive?: boolean | null
                isCustom: boolean
                value?: string | null
                datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
                sortOrder?: any | null
                entity?:
                  | {
                      __typename: 'Asset'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataElement'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataLifecycle'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataOwnership'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataSharing'
                      id: string
                      needsValue: boolean
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataSource'
                      id: string
                      needsValue: boolean
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'DataSubject'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'Department'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'LegalBasis'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'Process'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'ProcessingType'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'Region'
                      id: string
                      name: string
                      uiCode?: any | null
                    }
                  | {
                      __typename: 'SurveyAnswerOption'
                      id: string
                      name?: string | null
                      uiCode?: any | null
                    }
                  | null
              }
            | {
                __typename: 'DatamapCustomAnswerOption'
                uiCode?: any | null
                value?: string | null
                sortOrder?: any | null
                isSensitive?: boolean | null
                isCustom: boolean
                datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
              }
            | {
                __typename: 'SurveyAnswerOption'
                id: string
                value?: string | null
                uiCode?: any | null
                sortOrder?: any | null
              }
            | { __typename: 'SurveyVirtualAnswerOption' }
          >
          type: {
            __typename: 'QuestionType'
            id: string
            name: string
            code: string
            availableModifiers?: any | null
            isActive: boolean
            uiCode: any
          }
        }>
      }> | null
      questions: Array<{
        __typename: 'SurveyQuestion'
        id: string
        maturityScore?: any | null
        uiCode?: any | null
        modifiers?: any | null
        label: string
        description?: string | null
        subcategoryId: string
        sortOrder?: any | null
        datamapEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
        datamapParentEntityTypeCode?: Types.SurveyDatamapEntityTypeEnum | null
        guidedStepId?: string | null
        questionTemplateId?: string | null
        isTemplateQuestion?: boolean | null
        answerOptions: Array<
          | {
              __typename: 'DatamapAnswerOption'
              id: string
              isSensitive?: boolean | null
              isCustom: boolean
              value?: string | null
              datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
              sortOrder?: any | null
              entity?:
                | {
                    __typename: 'Asset'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataElement'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataLifecycle'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataOwnership'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSharing'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSource'
                    id: string
                    needsValue: boolean
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'DataSubject'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Department'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'LegalBasis'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Process'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'ProcessingType'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'Region'
                    id: string
                    name: string
                    uiCode?: any | null
                  }
                | {
                    __typename: 'SurveyAnswerOption'
                    id: string
                    name?: string | null
                    uiCode?: any | null
                  }
                | null
            }
          | {
              __typename: 'DatamapCustomAnswerOption'
              uiCode?: any | null
              value?: string | null
              sortOrder?: any | null
              isSensitive?: boolean | null
              isCustom: boolean
              datamapEntityTypeCode: Types.SurveyDatamapEntityTypeEnum
            }
          | {
              __typename: 'SurveyAnswerOption'
              id: string
              value?: string | null
              uiCode?: any | null
              sortOrder?: any | null
            }
          | { __typename: 'SurveyVirtualAnswerOption' }
        >
        type: {
          __typename: 'QuestionType'
          id: string
          name: string
          code: string
          availableModifiers?: any | null
          isActive: boolean
          uiCode: any
        }
      }>
    }>
  }
}

export type SurveyManagerPageSessionFragment = {
  __typename: 'SurveySession'
  id: string
  startedAt: any
  finishedAt: any
  deadlineAt?: any | null
  isCompleted: boolean
}

export const SurveyAnswerOptionDetailsFragmentDoc = gql`
  fragment SurveyAnswerOptionDetails on SurveyAnswerOption {
    id
    value
    uiCode
    sortOrder
  }
`
export const DatamapEntityDetailsFragmentDoc = gql`
  fragment DatamapEntityDetails on IDatamapEntity {
    id
    name
    uiCode
    ... on DataSharing {
      id
      needsValue
    }
    ... on DataSource {
      id
      needsValue
    }
  }
`
export const DatamapAnswerOptionDetailsFragmentDoc = gql`
  fragment DatamapAnswerOptionDetails on DatamapAnswerOption {
    id
    isSensitive
    isCustom
    value
    datamapEntityTypeCode
    entity {
      ...DatamapEntityDetails
    }
    sortOrder
  }
  ${DatamapEntityDetailsFragmentDoc}
`
export const DatamapCustomAnswerOptionDetailsFragmentDoc = gql`
  fragment DatamapCustomAnswerOptionDetails on DatamapCustomAnswerOption {
    uiCode
    value
    sortOrder
    isSensitive
    isCustom
    datamapEntityTypeCode
  }
`
export const ISurveyAnswerOptionDetailsFragmentDoc = gql`
  fragment ISurveyAnswerOptionDetails on ISurveyAnswerOption {
    ...SurveyAnswerOptionDetails
    ...DatamapAnswerOptionDetails
    ...DatamapCustomAnswerOptionDetails
  }
  ${SurveyAnswerOptionDetailsFragmentDoc}
  ${DatamapAnswerOptionDetailsFragmentDoc}
  ${DatamapCustomAnswerOptionDetailsFragmentDoc}
`
export const SurveyQuestionDetailsFragmentDoc = gql`
  fragment SurveyQuestionDetails on SurveyQuestion {
    id
    uiCode
    modifiers
    label
    description
    subcategoryId
    sortOrder
    datamapEntityTypeCode
    datamapParentEntityTypeCode
    guidedStepId
    answerOptions {
      ...ISurveyAnswerOptionDetails
    }
    questionTemplateId
    isTemplateQuestion @client
    type {
      id
      name
      code
      availableModifiers
      isActive
      uiCode
    }
  }
  ${ISurveyAnswerOptionDetailsFragmentDoc}
`
export const GuidedStepsDetailsFragmentDoc = gql`
  fragment GuidedStepsDetails on SurveyGuidedStep {
    id
    name
    description
    sortOrder
  }
`
export const SurveySubcategoryDetailsFragmentDoc = gql`
  fragment SurveySubcategoryDetails on SurveySubcategory {
    id
    header
    uiCode
    description
    isGuided
    sortOrder
    questionsCount
    questions {
      ...SurveyQuestionDetails
    }
    guidedSteps {
      ...GuidedStepsDetails
    }
    subcategoryTemplate {
      id
      header
      isSelected @client
      isConfirmed @client
      description
      sortOrder
      questionTemplatesCount
      category {
        id
        regulation {
          id
          name
          uiCode
        }
      }
    }
  }
  ${SurveyQuestionDetailsFragmentDoc}
  ${GuidedStepsDetailsFragmentDoc}
`
export const SurveyDetailsFragmentDoc = gql`
  fragment SurveyDetails on Survey {
    id
    surveyId
    name
    questionsCount
    isDraft
    isTemplate
    type
    createdAt
    lastUsedAt
    sessions {
      id
      finishedAt
      deadlineAt
    }
    subcategories {
      ...SurveySubcategoryDetails
    }
  }
  ${SurveySubcategoryDetailsFragmentDoc}
`
export const QuestionTemplateDetailsFragmentDoc = gql`
  fragment QuestionTemplateDetails on SurveyQuestionTemplate {
    id
    uiCode
    modifiers
    label
    description
    sortOrder
    answerOptionTemplates {
      id
      value
    }
    type {
      id
      name
      code
      availableModifiers
      isActive
      uiCode
    }
  }
`
export const SurveySubcategoryTemplateDetailsFragmentDoc = gql`
  fragment SurveySubcategoryTemplateDetails on SurveySubcategoryTemplate {
    id
    uiCode
    isSelected @client
    isConfirmed @client
    isActive
    isGuided
    header
    description
    sortOrder
    questionTemplates {
      ...QuestionTemplateDetails
    }
    questionTemplatesCount
    category {
      id
      regulation {
        id
        name
        uiCode
      }
    }
  }
  ${QuestionTemplateDetailsFragmentDoc}
`
export const AudienceUserDetailsFragmentDoc = gql`
  fragment AudienceUserDetails on User {
    id
    userId
    firstName
    lastName
    email
    status
    roles(input: { filter: { companyIds: [$companyId] } }) {
      userId
      roleId
      name
      companyId
      code
    }
    positions(input: { filter: { companyIds: [$companyId] } }) {
      position {
        id
        name
        companyId
      }
      department {
        id
        name
        companyId
      }
    }
  }
`
export const AudienceDetailsFragmentDoc = gql`
  fragment AudienceDetails on Audience {
    id
    name
    description
    isSingleUser
    updatedAt
    createdAt
    computingMethod
    users {
      ...AudienceUserDetails
    }
  }
  ${AudienceUserDetailsFragmentDoc}
`
export const ResponseDetailsFragmentDoc = gql`
  fragment ResponseDetails on SurveyResponse {
    id
    value
    answeredAt
    respondentId
    files {
      id
      originalFilename
    }
    answerOption {
      id
    }
  }
`
export const DatamapResponseCommonFragmentDoc = gql`
  fragment DatamapResponseCommon on IDatamapResponse {
    id
    responseId
    parentResponseId
    datamapEntityTypeCode
    datamapParentEntityTypeCode
    entity {
      ...DatamapEntityDetails
    }
    ... on DatamapDataSharingResponse {
      id
      sharedWithDepartment {
        id
        name
        uiCode
      }
      sharedWith3rdParty
    }
    ... on DatamapDataSourceResponse {
      id
      providedByDepartment {
        id
        name
        uiCode
      }
      providedBy3rdParty
      isCustom
    }
    ... on DatamapDataElementResponse {
      id
      isCustom
      isSensitive
    }
  }
  ${DatamapEntityDetailsFragmentDoc}
`
export const SurveyManagerDatamapResponseGetFragmentDoc = gql`
  fragment SurveyManagerDatamapResponseGet on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      id
      value
      questionId
      respondentId
      question {
        id
        sortOrder
      }
    }
  }
  ${DatamapResponseCommonFragmentDoc}
`
export const DatamapResponseResponseCommonFragmentDoc = gql`
  fragment DatamapResponseResponseCommon on SurveyResponse {
    id
    value
    respondentId
    questionId
    question {
      id
      sortOrder
    }
  }
`
export const DatamapResponseGetFragmentDoc = gql`
  fragment DatamapResponseGet on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      ...DatamapResponseResponseCommon
    }
  }
  ${DatamapResponseCommonFragmentDoc}
  ${DatamapResponseResponseCommonFragmentDoc}
`
export const DatamapResponseResponseDetailsFragmentDoc = gql`
  fragment DatamapResponseResponseDetails on SurveyResponse {
    ...DatamapResponseResponseCommon
    answerOption {
      id
    }
    question {
      id
      modifiers
      type {
        id
        name
        code
        availableModifiers
        isActive
        uiCode
      }
      datamapEntityTypeCode
      guidedStep {
        id
        sortOrder
      }
    }
  }
  ${DatamapResponseResponseCommonFragmentDoc}
`
export const DatamapResponseDetailsFragmentDoc = gql`
  fragment DatamapResponseDetails on IDatamapResponse {
    ...DatamapResponseCommon
    response {
      ...DatamapResponseResponseDetails
    }
  }
  ${DatamapResponseCommonFragmentDoc}
  ${DatamapResponseResponseDetailsFragmentDoc}
`
export const SurveyGetBuilderFragmentDoc = gql`
  fragment SurveyGetBuilderFragment on Survey {
    id
    surveyId
    name
    questionsCount
    isDraft
    isTemplate
    type
    createdAt
    lastUsedAt
    sessions {
      id
      finishedAt
      deadlineAt
    }
  }
`
export const SurveyManagerPageSessionFragmentDoc = gql`
  fragment SurveyManagerPageSession on SurveySession {
    id
    startedAt
    finishedAt
    deadlineAt
    isCompleted
  }
`
export const SurveyManagerViewAnswersSessionFragmentDoc = gql`
  fragment SurveyManagerViewAnswersSession on SurveySession {
    ...SurveyManagerPageSession
    maturityScoreAveragesForSession {
      ...MaturityScoreAverages
    }
    invitedUsers {
      id
    }
    survey {
      id
      name
      type
      questionsCount
      audiences {
        id
      }
      company {
        id
      }
      categories {
        id
        name
        regulation {
          id
          name
          uiCode
        }
      }
      subcategories {
        id
        header
        description
        sortOrder
        isGuided
        subcategoryTemplate {
          id
          category {
            id
            name
            regulation {
              id
              name
              uiCode
            }
          }
        }
        guidedSteps {
          id
          name
          description
          sortOrder
          questions {
            ...SurveyQuestionDetails
            maturityScore(sessionId: $sessionId)
          }
        }
        questions {
          id
          ...SurveyQuestionDetails
          maturityScore(sessionId: $sessionId)
        }
      }
    }
  }
  ${SurveyManagerPageSessionFragmentDoc}
  ${MaturityScoreAveragesFragmentDoc}
  ${SurveyQuestionDetailsFragmentDoc}
`
export const namedOperations = {
  Fragment: {
    SurveyDetails: 'SurveyDetails',
    SurveySubcategoryTemplateDetails: 'SurveySubcategoryTemplateDetails',
    GuidedStepsDetails: 'GuidedStepsDetails',
    SurveySubcategoryDetails: 'SurveySubcategoryDetails',
    ISurveyAnswerOptionDetails: 'ISurveyAnswerOptionDetails',
    DatamapEntityDetails: 'DatamapEntityDetails',
    DatamapAnswerOptionDetails: 'DatamapAnswerOptionDetails',
    DatamapCustomAnswerOptionDetails: 'DatamapCustomAnswerOptionDetails',
    SurveyAnswerOptionDetails: 'SurveyAnswerOptionDetails',
    SurveyQuestionDetails: 'SurveyQuestionDetails',
    QuestionTemplateDetails: 'QuestionTemplateDetails',
    AudienceDetails: 'AudienceDetails',
    AudienceUserDetails: 'AudienceUserDetails',
    ResponseDetails: 'ResponseDetails',
    DatamapResponseResponseCommon: 'DatamapResponseResponseCommon',
    DatamapResponseResponseDetails: 'DatamapResponseResponseDetails',
    SurveyManagerDatamapResponseGet: 'SurveyManagerDatamapResponseGet',
    DatamapResponseGet: 'DatamapResponseGet',
    DatamapResponseCommon: 'DatamapResponseCommon',
    DatamapResponseDetails: 'DatamapResponseDetails',
    SurveyGetBuilderFragment: 'SurveyGetBuilderFragment',
    SurveyManagerViewAnswersSession: 'SurveyManagerViewAnswersSession',
    SurveyManagerPageSession: 'SurveyManagerPageSession',
  },
}
