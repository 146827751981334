import { QuestionType } from '@generated/types.d'
import dayjs from 'dayjs'

export const REQUIRED_QUESTIONS = [
  {
    title: 'Business Process. What business processes do you participate in? ',
    description:
      'Processes should describe your day-to-day activities. For some you may know the process name already, for others you may never considered it a process but it forms an important part of your activities. Use the filter functions and suggestions from the drop-down menu to help map out your processes. Should you not find anything fitting, you can also create your own processes.',
  },
  {
    title: 'Data Subject. Who’s information is processed?',
    description:
      'Whether you have access to clients’, employees’ or contractors’ information, list below the type of data you deal with.',
  },
  {
    title:
      'Tools/Assets. What programmes, websites, files do you use to execute each process?',
    description:
      'Think carefully about all the tools (assets) you use for each process to collect, process or store information. This can be digital tools, like software and systems but also physical tools like filing rooms, laptops and mobile phones. Use the filter functions and drop down menuortype your answers.',
  },
  {
    title:
      'Tools/Assets. What programmes, websites, files do you use to execute each process?',
    description:
      'Think carefully about all the tools (assets) you use for each process to collect, process or store information. This can be digital tools, like software and systems but also physical tools like filing rooms, laptops and mobile phones. Use the filter functions and drop down menuortype your answers.',
  },
  {
    title:
      'Data Element(s). What data from the tools is used for each of the processes?',
    description:
      'Every personal data point (data element) you deal with for your work needs to be added here. ',
  },
  {
    title: 'Source of Data. What is the source of the data?',
    description:
      'Where does the data come from? This can be through a system you use, collection forms or internal handovers. ',
  },
  {
    title:
      'Data Ownership. Is your department the data controller or processor?',
    description:
      'For each process, define whether you’re in control over what happens with the data, or if you’re the processor. ',
  },
  {
    title:
      'Legal Basis. What is the legal basis (reason) for processing the data elements?',
    description:
      'For each process, define why you need to use the data. Consult your legal counsel in case of doubt.',
  },
]

export const SURVEY_TYPE = ({
  intl,
}: {
  intl: any
  questionTypes: Partial<QuestionType>[]
}) => {
  return [
    {
      icon: 'text',
      label: intl.formatMessage({
        id: 'FREE_TEXT',
        defaultMessage: 'Free Text',
      }),
      type: 'free-text',
    },
    {
      icon: 'ranking',
      label: intl.formatMessage({ id: 'LIST', defaultMessage: 'List' }),
      type: 'list',
    },
    {
      icon: 'list',
      label: intl.formatMessage({
        id: 'MULTIPLE_CHOICE',
        defaultMessage: 'Multiple Choice',
      }),
      type: 'multiple-selection',
    },
    {
      icon: 'dropdown-square',
      label: intl.formatMessage({ id: 'DROPDOWN', defaultMessage: 'Dropdown' }),
      type: 'dropdown',
    },
    {
      icon: 'rating',
      label: intl.formatMessage({
        id: 'RATING_SCALE',
        defaultMessage: 'Rating Scale',
      }),
      type: 'rating',
    },
    {
      icon: 'matrix',
      label: intl.formatMessage({ id: 'MATRIX', defaultMessage: 'Matrix' }),
      type: 'matrix-rating',
    },
    {
      icon: 'attach',
      label: intl.formatMessage({ id: 'DOCUMENT', defaultMessage: 'Document' }),
      type: 'document',
    },
  ]
}

export const STAR_RATING_SCALE = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
]

export const DEFAULT_SURVEY_ANSWERS = [
  {
    user: {
      name: 'Justin Jackson',
      department: 'Management',
      color: '#b971de',
    },
    date: '22 October 2019',
    required: [
      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },

      {
        businessProcess: 'Time management',
        dataSubject: 'Prospects',
        toolsAssets: [
          {
            title: 'Salesforce',
            logo: '/static/svg/logos/salesforce/logo.png',
          },
          {
            title: 'Shopify',
            logo: '/static/svg/logos/shopify/logo.png',
          },
        ],
        dataElements: [
          { elements: ['Address'] },
          { elements: ['Email Communication'] },
        ],
        sourceOfData: ['Website.com', 'Data Subject'],
        dataOwnership: ['Data Processor', ''],
        legalBasis: ['Contract', ''],
      },
      {
        businessProcess: 'Client Onboarding',
        dataSubject: 'Client',
        toolsAssets: [
          { title: 'AWS S3', logo: '/static/svg/logos/aws-s3/logo.png' },
        ],
        dataElements: [{ elements: ['Address', 'Email'] }],
        sourceOfData: ['Salesforce'],
        dataOwnership: ['Data Processor'],
        legalBasis: ['Consent'],
      },
      {
        businessProcess: 'Lead Generation',
        dataSubject: 'Employee',
        toolsAssets: [
          {
            title: 'Microsoft Azure',
            logo: '/static/svg/logos/azure-app-service/logo.png',
          },
        ],
        dataElements: [{ elements: ['Email Communication'] }],
        sourceOfData: ['Local filing system'],
        dataOwnership: ['Data Controller'],
        legalBasis: ['Contract'],
      },
    ],
    personal: [
      {
        type: 'multiple-selection',
        title:
          'Overall, how satisfied or dissatisfied are you with our company?',
        options: [
          'Very satisfied',
          'Somewhat satisfied',
          'Neither satisfied nor dissatisfied',
          'Neither dissatisfied nor satisfied',
          'Somewhat dissatisfied',
          'Very dissatisfied',
        ],
        forced: true,
        answer: 'Somewhat satisfied',
      },

      {
        type: 'dropdown',
        title:
          'Overall, how satisfied or dissatisfied are you with our company?',
        options: [
          'Very satisfied',
          'Somewhat satisfied',
          'Neither satisfied nor dissatisfied',
          'Neither dissatisfied nor satisfied',
          'Somewhat dissatisfied',
          'Very dissatisfied',
        ],
        answer: 'Somewhat satisfied',
      },

      {
        type: 'rating',
        title:
          'Overall, how satisfied or dissatisfied are you with our company?',
        scale: 5,
        color: '#ffcd2d',
        answer: 3,
      },
    ],
  },

  {
    user: {
      name: 'Jesse Silva',
      department: 'COO',
      color: '#62a8e0',
    },
    date: '22 October 2019',
  },

  {
    user: {
      name: 'Ellen Green',
      department: 'Commercial Director',
      color: '#7f82d0',
    },
    date: '22 October 2019',
  },
]

export const DEFAULT_SURVEY_AUDIENCES = {
  CustomAudiences: [
    {
      title: 'Audiences Group 1',
      audiences: [
        {
          name: 'Dustin Rose',
          role: 'Management',
          department: 'Commercial',
          email: 'mayert_vern@mia.net',
          processes: 'Prospecting, Client Onboarding',
          color: '#ddbe7d',
        },
        {
          name: 'Mayme Griffith',
          role: 'COO',
          department: 'Commercial',
          email: 'gerhold.karl@yahoo.com',
          processes: 'Monthly Newsletter, Strategy Planning',
          color: '#d788d7',
        },
        {
          name: 'Bernard Carson',
          role: 'Commercial Director',
          department: 'Advisory Board',
          email: 'dalton.hane@elis.info',
          processes: 'Billing, Compliance Assessment',
          color: '#57c2db',
        },
        {
          name: 'Willie Alexander',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#6594dd',
        },
        {
          name: 'Lora Myers',
          role: 'DPO Advisor',
          department: 'Product Development',
          email: 'lee.schoen@yahoo.com',
          processes: 'User Research, Product Iteration',
          color: '#ddbe7d',
        },
        {
          name: 'Warren West',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#57c2db',
        },
      ],
      updatedDate: '22 October 2019',
    },
    {
      title: 'Audiences Group 2',
      audiences: [
        {
          name: 'Mustafa Uyers',
          role: 'Management',
          department: 'Commercial',
          email: 'mayert_vern@mia.net',
          processes: 'Prospecting, Client Onboarding',
          color: '#d788d7',
        },
        {
          name: 'Rayme Sriffith',
          role: 'COO',
          department: 'Commercial',
          email: 'gerhold.karl@yahoo.com',
          processes: 'Monthly Newsletter, Strategy Planning',
          color: '#57c2db',
        },
        {
          name: 'Rernard Varson',
          role: 'Commercial Director',
          department: 'Advisory Board',
          email: 'dalton.hane@elis.info',
          processes: 'Billing, Compliance Assessment',
          color: '#f995c4',
        },
        {
          name: 'Willie Alexander',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#6594dd',
        },
        {
          name: 'Lora Myers',
          role: 'DPO Advisor',
          department: 'Product Development',
          email: 'lee.schoen@yahoo.com',
          processes: 'User Research, Product Iteration',
          color: '#ddbe7d',
        },
        {
          name: 'Warren West',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#57c2db',
        },
        {
          name: 'Bruce Scott',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#ddbe7d',
        },
        {
          name: 'Lora Myers',
          role: 'DPO Advisor',
          department: 'Product Development',
          email: 'lee.schoen@yahoo.com',
          processes: 'User Research, Product Iteration',
          color: '#ddbe7d',
        },
        {
          name: 'Warren West',
          role: 'Technical Advisor',
          department: 'Manager',
          email: 'conroy.nicola@josefina.io',
          processes: 'Contracts Renewal',
          color: '#6594dd',
        },
      ],
      updatedDate: '18 September 2018',
    },
  ],
  ProcessOwners: [
    {
      name: 'Dustin Rose',
      role: 'Management',
      department: 'Commercial',
      email: 'mayert_vern@mia.net',
      processes: 'Prospecting, Client Onboarding',
      color: '#ddbe7d',
    },
    {
      name: 'Mayme Griffith',
      role: 'COO',
      department: 'Commercial',
      email: 'gerhold.karl@yahoo.com',
      processes: 'Monthly Newsletter, Strategy Planning',
      color: '#d788d7',
    },
    {
      name: 'Bernard Carson',
      role: 'Commercial Director',
      department: 'Advisory Board',
      email: 'dalton.hane@elis.info',
      processes: 'Billing, Compliance Assessment',
      color: '#57c2db',
    },
    {
      name: 'Willie Alexander',
      role: 'Technical Advisor',
      department: 'Manager',
      email: 'conroy.nicola@josefina.io',
      processes: 'Contracts Renewal',
      color: '#6594dd',
    },
    {
      name: 'Lora Myers',
      role: 'DPO Advisor',
      department: 'Product Development',
      email: 'lee.schoen@yahoo.com',
      processes: 'User Research, Product Iteration',
      color: '#ddbe7d',
    },
    {
      name: 'Lura Holland',
      role: 'Management',
      department: 'Commercial',
      email: 'mayert_vern@mia.net',
      processes: 'Texhnology Recruitment',
      color: '#ddbe7d',
    },
    {
      name: 'Eula Keller',
      role: 'COO',
      department: 'Commercial',
      email: 'gerhold.karl@yahoo.com',
      processes: 'Performance Review',
      color: '#ddbe7d',
    },
    {
      name: 'Bruce Scott',
      role: 'Commercial Director',
      department: 'Advisory Board',
      email: 'dalton.hane@ellis.info',
      processes: 'Product Testing, Bug Fixing',
      color: '#ddbe7d',
    },
    {
      name: 'Amella Agullar',
      role: 'Technical Advisor',
      department: 'Manager',
      email: 'conroy.nicola@josefina.io',
      processes: 'Client Retention',
      color: '#ddbe7d',
    },
    {
      name: 'Ophella Quinn',
      role: 'DPO Advisor',
      department: 'Product Development',
      email: 'lee.schoen@yahoo.com',
      processes: 'Development Sprints',
      color: '#ddbe7d',
    },
    {
      name: 'Mustafa Uyers',
      role: 'Management',
      department: 'Commercial',
      email: 'mayert_vern@mia.net',
      processes: 'Prospecting, Client Onboarding',
      color: '#d788d7',
    },
    {
      name: 'Rayme Sriffith',
      role: 'COO',
      department: 'Commercial',
      email: 'gerhold.karl@yahoo.com',
      processes: 'Monthly Newsletter, Strategy Planning',
      color: '#57c2db',
    },
    {
      name: 'Rernard Varson',
      role: 'Commercial Director',
      department: 'Advisory Board',
      email: 'dalton.hane@elis.info',
      processes: 'Billing, Compliance Assessment',
      color: '#f995c4',
    },
    {
      name: 'Andre Alexander',
      role: 'Technical Advisor',
      department: 'Manager',
      email: 'conroy.nicola@josefina.io',
      processes: 'Contracts Renewal',
      color: '#f995c4',
    },
    {
      name: 'Lora Medic',
      role: 'DPO Advisor',
      department: 'Product Development',
      email: 'lee.schoen@yahoo.com',
      processes: 'User Research, Product Iteration',
      color: '#f995c4',
    },
  ],
}

export const SurveyTypes = [
  {
    _id: 1,
    title: 'GDPR',
    type: 'gdpr',
  },
  {
    _id: 2,
    title: 'CCPA',
    type: 'ccpa',
  },
  {
    _id: 3,
    title: 'LGPD',
    type: 'lgpd',
  },
  {
    _id: 4,
    title: 'GDPR Process Mapping',
    type: 'gdpr',
  },
  {
    _id: 5,
    title: 'LGPD Asset Mapping',
    type: 'lgpd',
  },
]

export const groupedAnswers = [
  dayjs(new Date())
    .add(Math.floor(Math.random() * (31 - 1 + 1) + 1), 'day')
    .add(2, 'month')
    .format('DD MMMM YYYY'),
  dayjs(new Date())
    .add(Math.floor(Math.random() * (31 - 1 + 1) + 1), 'day')
    .add(1, 'month')
    .format('DD MMMM YYYY'),
  dayjs(new Date()).format('DD MMMM YYYY'),
]
