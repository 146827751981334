/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  StatisticsCompletedSurveysGetDocument,
  StatisticsCompletedSurveysGetQueryVariables,
  StatisticsRespondedQuestionsGetDocument,
  StatisticsRespondedQuestionsGetQueryVariables,
  StatisticsWorkforcePercentageGetDocument,
  StatisticsWorkforcePercentageGetQueryVariables,
} from '@generated/types.d'
import { PqApolloClient } from './index'

export const statisticsRespondedQuestionsGet = async (
  client: PqApolloClient,
  variables: StatisticsRespondedQuestionsGetQueryVariables,
  graphqlDocument: typeof StatisticsRespondedQuestionsGetDocument = StatisticsRespondedQuestionsGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const statisticsCompletedSurveysGet = async (
  client: PqApolloClient,
  variables: StatisticsCompletedSurveysGetQueryVariables,
  graphqlDocument: typeof StatisticsCompletedSurveysGetDocument = StatisticsCompletedSurveysGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const statisticsWorkforcePercentageGet = async (
  client: PqApolloClient,
  variables: StatisticsWorkforcePercentageGetQueryVariables,
  graphqlDocument: typeof StatisticsWorkforcePercentageGetDocument = StatisticsWorkforcePercentageGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
