import {
  GeneralDepartmentTemplatesGetDocument,
  GeneralDepartmentTemplatesGetQuery,
  GeneralPositionTemplatesGetDocument,
  GeneralPositionTemplatesGetQuery,
  GeneralProcessTemplateListDocument,
  GeneralProcessTemplateListQuery,
} from '@generated/types.d'

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ApolloQueryResult } from '@palqee/apollo-client'
import { PqApolloClient } from './index'

type PqApiCall<T> = (
  client: PqApolloClient,
  variables?: any,
  graphqlDocument?: any,
) => Promise<T>

export const generalProcessTemplateList: PqApiCall<
  ApolloQueryResult<GeneralProcessTemplateListQuery>
> = async (
  client: PqApolloClient,
  _?: any,
  graphqlDocument: typeof GeneralProcessTemplateListDocument = GeneralProcessTemplateListDocument,
) => {
  return client.query({
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const generalDepartmentTemplatesGet: PqApiCall<
  ApolloQueryResult<GeneralDepartmentTemplatesGetQuery>
> = async (
  client: PqApolloClient,
  variables = null,
  graphqlDocument: typeof GeneralDepartmentTemplatesGetDocument = GeneralDepartmentTemplatesGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const generalPositionTemplatesGet: PqApiCall<
  ApolloQueryResult<GeneralPositionTemplatesGetQuery>
> = async (
  client: PqApolloClient,
  variables = null,
  graphqlDocument: typeof GeneralPositionTemplatesGetDocument = GeneralPositionTemplatesGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}
