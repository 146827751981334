import * as Types from '../../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type MaturityScoreAveragesFragment = {
  __typename: 'StatisticsMaturityScoreAveragesItem'
  sessionId?: string | null
  maturityScoreSum?: any | null
  maturityScoreAverage?: number | null
  maturityScoreAverageSum?: number | null
  surveysCount: any
  questionsCount: any
}

export type SurveyMaturityScoreSetMutationVariables = Types.Exact<{
  input: Types.SurveyMaturityScoreSetInput
  filter?: Types.InputMaybe<Types.StatisticsMaturityScoreAveragesFilterArg>
  groupBy?: Types.InputMaybe<
    | Array<Types.StatisticsMaturityScoreAveragesGroupByEnum>
    | Types.StatisticsMaturityScoreAveragesGroupByEnum
  >
}>

export type SurveyMaturityScoreSetMutation = {
  __typename: 'Mutation'
  survey: {
    __typename: 'NamespaceSurveyMutation'
    maturityScoreSet: {
      __typename: 'SurveyMaturityScoreSetPayload'
      query: {
        __typename: 'Query'
        statistics: {
          __typename: 'NamespaceStatisticsQuery'
          maturityScoreAverages: {
            __typename: 'StatisticsMaturityScoreAveragesPayload'
            items: Array<{
              __typename: 'StatisticsMaturityScoreAveragesItem'
              sessionId?: string | null
              maturityScoreSum?: any | null
              maturityScoreAverage?: number | null
              maturityScoreAverageSum?: number | null
              surveysCount: any
              questionsCount: any
            }>
          }
        }
      }
      maturityScore?: {
        __typename: 'MaturityScore'
        questionId: string
        sessionId: string
        maturityScore: any
        session: {
          __typename: 'SurveySession'
          id: string
          maturityScoreAveragesForSession?: {
            __typename: 'StatisticsMaturityScoreAveragesItem'
            sessionId?: string | null
            maturityScoreSum?: any | null
            maturityScoreAverage?: number | null
            maturityScoreAverageSum?: number | null
            surveysCount: any
            questionsCount: any
          } | null
          survey: {
            __typename: 'Survey'
            id: string
            name: string
            questionsCount: any
          }
        }
      } | null
    }
  }
}

export const MaturityScoreAveragesFragmentDoc = gql`
  fragment MaturityScoreAverages on StatisticsMaturityScoreAveragesItem {
    sessionId
    maturityScoreSum
    maturityScoreAverage
    maturityScoreAverageSum
    surveysCount
    questionsCount
  }
`
export const SurveyMaturityScoreSetDocument = gql`
  mutation SurveyMaturityScoreSet(
    $input: SurveyMaturityScoreSetInput!
    $filter: StatisticsMaturityScoreAveragesFilterArg
    $groupBy: [StatisticsMaturityScoreAveragesGroupByEnum!]
  ) {
    survey {
      maturityScoreSet(input: $input) {
        query {
          statistics {
            maturityScoreAverages(
              input: { filter: $filter, groupBy: $groupBy }
            ) {
              items {
                ...MaturityScoreAverages
              }
            }
          }
        }
        maturityScore {
          questionId
          sessionId
          maturityScore
          session {
            id
            maturityScoreAveragesForSession {
              ...MaturityScoreAverages
            }
            survey {
              id
              name
              questionsCount
            }
          }
        }
      }
    }
  }
  ${MaturityScoreAveragesFragmentDoc}
`
export type SurveyMaturityScoreSetMutationFn = Apollo.MutationFunction<
  SurveyMaturityScoreSetMutation,
  SurveyMaturityScoreSetMutationVariables
>

/**
 * __useSurveyMaturityScoreSetMutation__
 *
 * To run a mutation, you first call `useSurveyMaturityScoreSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSurveyMaturityScoreSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [surveyMaturityScoreSetMutation, { data, loading, error }] = useSurveyMaturityScoreSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      filter: // value for 'filter'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useSurveyMaturityScoreSetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SurveyMaturityScoreSetMutation,
    SurveyMaturityScoreSetMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SurveyMaturityScoreSetMutation,
    SurveyMaturityScoreSetMutationVariables
  >(SurveyMaturityScoreSetDocument, options)
}
export type SurveyMaturityScoreSetMutationHookResult = ReturnType<
  typeof useSurveyMaturityScoreSetMutation
>
export type SurveyMaturityScoreSetMutationResult =
  Apollo.MutationResult<SurveyMaturityScoreSetMutation>
export type SurveyMaturityScoreSetMutationOptions = Apollo.BaseMutationOptions<
  SurveyMaturityScoreSetMutation,
  SurveyMaturityScoreSetMutationVariables
>
export const namedOperations = {
  Mutation: {
    SurveyMaturityScoreSet: 'SurveyMaturityScoreSet',
  },
  Fragment: {
    MaturityScoreAverages: 'MaturityScoreAverages',
  },
}
