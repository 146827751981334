import axios from 'axios'
import { getSession } from 'next-auth/react'

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_FILE_API_URL, // todo: fix this
})

// Add a request interceptor
instance.interceptors.request.use(async (config) => {
  // use next-auth session to get accessToken
  const session = await getSession()
  const token = session?.accessToken

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default instance
