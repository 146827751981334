import {
  ApolloQueryResult,
  MutationOptions,
  QueryOptions,
} from '@palqee/apollo-client'
import {
  CompanyAudienceCreateDocument,
  CompanyAudienceCreateMutationVariables,
  CompanyAudienceUsersLinkDocument,
  CompanyAudienceUsersLinkMutationVariables,
  CompanyAudienceUsersUnlinkDocument,
  CompanyAudienceUsersUnlinkMutationVariables,
  CompanyDeleteDocument,
  CompanyDeleteMutationVariables,
  CompanyDepartmentCreateDocument,
  CompanyDepartmentCreateMutationVariables,
  CompanyDepartmentsGetDocument,
  CompanyDepartmentsGetQuery,
  CompanyDepartmentsGetQueryVariables,
  CompanyFeaturesGetDocument,
  CompanyFeaturesGetQueryVariables,
  CompanyLogoSetDocument,
  CompanyLogoSetMutationVariables,
  CompanyOnboardingStepsGetDocument,
  CompanyOnboardingStepsGetQueryVariables,
  CompanyPositionCreateDocument,
  CompanyPositionCreateMutationVariables,
  CompanyPositionsGetDocument,
  CompanyPositionsGetQuery,
  CompanyPositionsGetQueryVariables,
  CompanyRegulationLinkDocument,
  CompanyRegulationLinkMutationVariables,
  CompanyRepresentativesGetDocument,
  CompanyRepresentativesGetQueryVariables,
} from '@generated/types.d'

import { PqApolloClient } from './index'
import { SurveyBuilderRegulatoryGetPageDocument } from '@modules/products/surveys/builder/regulatory/__generated__'
import axiosClient from '@api/axios'

type PqApiCall<T> = (
  client: PqApolloClient,
  variables?: any,
  graphqlDocument?: any,
  options?: any,
) => Promise<T>

export const generalCompanyOnboardingProgressList = async (
  client: PqApolloClient,
  variables: CompanyOnboardingStepsGetQueryVariables,
  graphqlDocument: typeof CompanyOnboardingStepsGetDocument = CompanyOnboardingStepsGetDocument,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    fetchPolicy: 'no-cache',
  })
}

export const companyDelete = async (
  client: PqApolloClient,
  variables: CompanyDeleteMutationVariables,
) => {
  return client.mutate({
    variables,
    mutation: CompanyDeleteDocument,
    update: (cache) => {
      cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'company',
      })
      cache.gc()
    },
  })
}

export const companyAudienceCreate = async (
  client: PqApolloClient,
  variables: CompanyAudienceCreateMutationVariables,
  graphqlDocument: typeof CompanyAudienceCreateDocument = CompanyAudienceCreateDocument,
  options?: Partial<
    MutationOptions<any, CompanyAudienceCreateMutationVariables>
  >,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    ...options,
  })
}

export const companyAudienceUsersLink = async (
  client: PqApolloClient,
  variables: CompanyAudienceUsersLinkMutationVariables,
  graphqlDocument: typeof CompanyAudienceUsersLinkDocument = CompanyAudienceUsersLinkDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    refetchQueries: [
      'UserGetCompanySurveysAudiences',
      'CompanyFetchAudienceGroups',
      'UserGet',
    ],
  })
}

export const companyAudienceUsersUnlink = async (
  client: PqApolloClient,
  variables: CompanyAudienceUsersUnlinkMutationVariables,
  graphqlDocument: typeof CompanyAudienceUsersUnlinkDocument = CompanyAudienceUsersUnlinkDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    refetchQueries: [],
  })
}

export const companyLogoSet = async (
  client: PqApolloClient,
  variables: CompanyLogoSetMutationVariables,
  graphqlDocument: typeof CompanyLogoSetDocument = CompanyLogoSetDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    fetchPolicy: 'no-cache',
    context: {
      hasUpload: true,
    },
  })
}

export const companyRegulationLink = async (
  client: PqApolloClient,
  variables: CompanyRegulationLinkMutationVariables,
  graphqlDocument: typeof CompanyRegulationLinkDocument = CompanyRegulationLinkDocument,
) => {
  return client.mutate({
    variables,
    mutation: graphqlDocument,
    refetchQueries: [{ query: SurveyBuilderRegulatoryGetPageDocument }],
  })
}

export const companyLogoFetchSrc = async (
  companyId: string,
  logoUrl: string,
): Promise<{ companyId: string; res: any }> => {
  const res = await axiosClient.get(logoUrl)
  return {
    companyId,
    res,
  }
}

export const companyRepresentativesGet = async (
  client: PqApolloClient,
  variables: CompanyRepresentativesGetQueryVariables,
  graphqlDocument: typeof CompanyRepresentativesGetDocument = CompanyRepresentativesGetDocument,
  options?: Partial<QueryOptions<CompanyRepresentativesGetQueryVariables>>,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    ...options,
  })
}

export const companyFeaturesGet = async (
  client: PqApolloClient,
  variables: CompanyFeaturesGetQueryVariables,
  graphqlDocument: typeof CompanyFeaturesGetDocument = CompanyFeaturesGetDocument,
  options?: Partial<QueryOptions<CompanyFeaturesGetQueryVariables>>,
) => {
  return client.query({
    variables,
    query: graphqlDocument,
    ...options,
  })
}

export const companyDepartmentCreate = async (
  client: PqApolloClient,
  variables: CompanyDepartmentCreateMutationVariables,
  document: typeof CompanyDepartmentCreateDocument = CompanyDepartmentCreateDocument,
  options?: Partial<
    MutationOptions<any, CompanyDepartmentCreateMutationVariables>
  >,
) => {
  return client.mutate({
    variables,
    mutation: document,
    fetchPolicy: 'no-cache',
    ...options,
  })
}

export const companyDepartmentsGet: PqApiCall<
  ApolloQueryResult<CompanyDepartmentsGetQuery>
> = async (
  client: PqApolloClient,
  variables: CompanyDepartmentsGetQueryVariables,
  document: typeof CompanyDepartmentsGetDocument = CompanyDepartmentsGetDocument,
) => {
  return client.query({
    variables,
    query: document,
    fetchPolicy: 'no-cache',
  })
}

export const companyPositionGet: PqApiCall<
  ApolloQueryResult<CompanyPositionsGetQuery>
> = async (
  client: PqApolloClient,
  variables: CompanyPositionsGetQueryVariables,
  document: typeof CompanyPositionsGetDocument = CompanyPositionsGetDocument,
) => {
  return client.query({
    variables,
    query: document,
    fetchPolicy: 'no-cache',
  })
}

export const companyPositionCreate = async (
  client: PqApolloClient,
  variables: CompanyPositionCreateMutationVariables,
  document: typeof CompanyPositionCreateDocument = CompanyPositionCreateDocument,
  options?: Partial<
    MutationOptions<any, CompanyPositionCreateMutationVariables>
  >,
) => {
  return client.mutate({
    variables,
    mutation: document,
    fetchPolicy: 'no-cache',
    ...options,
  })
}
